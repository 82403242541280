/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// ComplainActionFrom page components
import DetailsForm from "./components/Details";
import MasterCard from "examples/Cards/MasterCard";
import { withComplainContext } from "context/Complain";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Bill from "./components/Bill";
import ViewBill from "./components/Bill/ViewBill";
import Payment from "./components/Payment";
import Review from "./components/Review";
import VoiceNote from "./components/VoiceNote";
import User from "../../User/index";
import Task from "Pages/Task";
import Loader from "components/Loader";
import Comment from "Pages/Comment";

function ComplainActionFrom({
	DataGetComplain,
	setComplainCategoryId,
	setComplainId,
	setStateComplain,
	setPropertyComplainId,
	stateComplain,
	handleChangeComplain,
	setUserComplainId,
	LoadingComplain
}) {
	const { id, ComplainCategoryId: SOID, PropertyId } = useParams();

	useEffect(() => {
		if (id == "New") {
			setStateComplain({});
		}
	}, []);

	useEffect(() => {
		setComplainId(id);
	}, [id]);

	useEffect(() => {
		setComplainCategoryId(SOID);
	}, [SOID]);

	useEffect(() => {
		setPropertyComplainId(PropertyId);
	}, [PropertyId]);

	return (
		<DashboardLayout>
			<>
				<DashboardNavbar absolute isMini />
				{(!stateComplain?.User ||
					stateComplain?.User == "" ||
					!DataGetComplain?.User ||
					DataGetComplain?.User == "" ||
					DataGetComplain?.User == {}) &&
					id == "New" ? (
					<MDBox mt={8}>
						<MDBox mb={3}>
							<User
								setUser={(a) => {
									handleChangeComplain("User", a);
									setUserComplainId(a);
								}}
								PropertyIdComplain={PropertyId}
							/>
						</MDBox>
					</MDBox>
				) : (
					<MDBox mt={8}>
						<MDBox mb={3}>
							<Grid container spacing={3}>
								<Grid container spacing={3} xs={12} lg={8}>
									{id !== "New" && (
										<Grid item xs={12} lg={12}>
											<Task ServiceID={""} ComplainID={id} />
										</Grid>
									)}
									<Grid item xs={12} xl={6}>
										<MasterCard
											number={DataGetComplain?.Property?.description || "Property Name"}
											holder={DataGetComplain?.Property?.noRooms || "1"}
											expires={DataGetComplain?.Property?.noBathrooms || "1"}
											background={"assets/images/illustrations/pattern-tree.svg"}
										/>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<DetailsForm />
											</Grid>
										</Grid>
									</Grid>
									{id !== "New" && !DataGetComplain?.Payment?._id && (
										<Grid item xs={12} lg={6}>
											<Bill />
										</Grid>
									)}
									{DataGetComplain?.Bill?._id && (
										<>
											<Grid item xs={12} lg={6}>
												<ViewBill />
											</Grid>
											<Grid item xs={12} lg={6}>
												<Payment />
											</Grid>
										</>
									)}
									{DataGetComplain?.Review?._id && (
										<Grid item xs={12} lg={6}>
											<Review />
										</Grid>
									)}
								</Grid>
								{id !== "New" && (
									<Grid item xs={12} lg={3}>
										<Comment ServiceID={id} ComplainID={""} />
									</Grid>
								)}
							</Grid>
						</MDBox>
					</MDBox>
				)}
			</>
		</DashboardLayout>
	);
}

export default withComplainContext(ComplainActionFrom);
