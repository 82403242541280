/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import DetailsForm from "./components/Details";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";

function Billing({ Token, CheckToken }) {
  const { id, propertyId } = useParams();
  const [data, setData] = useState();
  const [error, setError] = useState("");

  const GetUtility = () => {
    if (Token || localStorage.getItem("token"))  {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_PATH}/UtilityInfo/${id}`, {
          headers: {
            Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res?.data?.status == 200) {
            setData(res?.data?.data);
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
        });
    } else {
      setTimeout(() => {
        CheckToken();
        GetUtility();
      }, 500);
    }
  };

  const [PropertyData, setPropertyData] = useState();

  const GetProperty = () => {
    if (Token || localStorage.getItem("token"))  {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_PATH}/PropertyInfo/${propertyId}`,
          {
            headers: {
              Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.status == 200) {
            setPropertyData(res?.data?.data);
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
        });
    } else {
      setTimeout(() => {
        CheckToken();
        GetProperty();
      }, 500);
    }
  };

  useEffect(() => {
    if (id !== "New") GetUtility();
    if (propertyId) GetProperty();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      {error ? (
        <p>{error}</p>
      ) : (
        <MDBox mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={6}>
              <MasterCard
                number={PropertyData?.description || "Property Name"}
                holder={PropertyData?.noRooms || "1"}
                expires={PropertyData?.noBathrooms || "1"}
                background={"assets/images/illustrations/pattern-tree.svg"}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DetailsForm
                id={id}
                GetUtility={GetUtility}
                data={data}
                propertyId={propertyId}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default withAuthContext(Billing);
