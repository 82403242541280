/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { withAuthContext } from "context/Auth";
import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceOfferedContext } from "context/ServiceOffered";
import { CheckBox } from "@mui/icons-material";
import { useState } from "react";
import swal from "sweetalert";
import Loader from "components/Loader";

function AddField({
	handleSubmit,
	stateServiceOffered,
	setAddField,
	handleChange,
	LoadingServiceOffered
}) {
	const [State, setState] = useState({
		name: "",
		type: "number",
		pricePerUnit: 0,
		forUser: false
	});

	const handleChangeFields = (name, value) => {
		setState({ ...State, [name]: value });
	};
	if (LoadingServiceOffered) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						AddField
					</MDTypography>
					<MDButton
						variant="gradient"
						color="dark"
						onClick={() => {
							if (
								State?.name !== "" ||
								State?.name !== " " ||
								State?.name !== null ||
								State?.name !== undefined ||
								!State?.name
							) {
								const arr = stateServiceOffered?.Fields || [];
								arr.push(State);
								handleChange("Fields", arr);
								setAddField(false);
								handleSubmit();
							} else {
								swal({
									text: "Please Fill All The Fields",
									button: {
										text: "Ok",
										closeModal: true
									},
									icon: "error",
									time: 3000
								});
							}
						}}
					>
						Add
					</MDButton>
				</MDBox>

				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								value={State?.name}
								label="name"
								variant="standard"
								fullWidth
								onChange={(e) => handleChangeFields("name", e.target.value)}
							/>
						</MDBox>
						<MDBox mb={2}>
							<InputLabel variant="standard" htmlFor="uncontrolled-native">
								Type
							</InputLabel>
							<Select
								value={State?.type}
								label="type"
								variant="standard"
								fullWidth
								onChange={(e) => handleChangeFields("type", e.target.value)}
							>
								<MenuItem value={"number"}>Number</MenuItem>
								<MenuItem value={"string"}>String</MenuItem>
							</Select>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								value={State?.pricePerUnit}
								label="pricePerUnit"
								variant="standard"
								disabled={State?.type == "string"}
								fullWidth
								onChange={(e) => handleChangeFields("pricePerUnit", e.target.value)}
							/>
						</MDBox>
						<MDBox mb={2} display="flex" justifyContent="flex-start" alignItems="center">
							<input
								type="checkbox"
								value={State?.forUser}
								onChange={(e) => handleChangeFields("forUser", !State?.forUser)}
							/>
							<MDTypography variant="p" fontWeight="medium" mx={2}>
								is Field For User
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withServiceOfferedContext(AddField);
