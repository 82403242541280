/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import DetailsForm from "./components/Details";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";

function Form({ Token, CheckToken }) {
  const { id} = useParams();
  const [error, setError] = useState("");


  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      {error ? (
        <p>{error}</p>
      ) : (
        <MDBox mt={8}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
              <DetailsForm id={id}  />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default withAuthContext(Form);
