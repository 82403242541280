/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { withAuthContext } from "context/Auth";
import { Card, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceOfferedContext } from "context/ServiceOffered";
import ImagePicker from "components/ImagePicker";
import Loader from "components/Loader";

function Details({ stateServiceOffered, handleSubmit, handleChange, id, LoadingServiceOffered }) {
	const handleImageSelected = (imageData) => {
		handleChange("Icon", imageData);
	};
	if (LoadingServiceOffered) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						Basic Details
					</MDTypography>
					<MDButton variant="gradient" color="dark" onClick={() => handleSubmit()}>
						{id !== "New" ? "update" : "Add"}
					</MDButton>
				</MDBox>

				<ImagePicker onImageSelected={handleImageSelected} preview={stateServiceOffered?.preview} />
				{/* {selectedImage && (
          <div>
            <h2>Selected Image Preview</h2>
            <img src={selectedImage} alt="Selected" className="selected-image" />
          </div>
        )} */}

				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								value={stateServiceOffered?.title}
								label="title"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("title", e.target.value)}
							/>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withServiceOfferedContext(Details);
