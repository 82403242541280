// src/components/ImagePicker.js
import React, { useState } from "react";
import "./ImagePicker.css"; // Import the CSS file

function ImagePicker({ onImageSelected, preview, disabled }) {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        onImageSelected({
          data: e.target.result,
          name: file.name,
          type: file.type,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="image-picker-input" // Use a class for the input
        id="image-picker"
        disabled={disabled}
      />
      <label htmlFor="image-picker" className="image-picker-label">
        {" "}
        {/* Use a class for the label */}
        <div className="image-picker-container">
          {selectedImage || preview ? (
            <img
              src={selectedImage || preview}
              alt="Selected"
              className="selected-image"
            />
          ) : (
            <div className="placeholder-text">Select an image</div>
          )}
        </div>
      </label>
    </div>
  );
}

export default ImagePicker;
