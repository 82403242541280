import React from "react";

const Loader = ({ loaderColor = "#3498db" }) => {
	const loaderStyle = {
		border: "16px solid #f3f3f3",
		borderRadius: "50%",
		borderTop: `16px solid ${loaderColor}`,
		width: "120px",
		height: "120px",
		animation: "spin 2s linear infinite"
	};

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				alignContent: "center",
				alignItems: "center",
				justifyContent: "center",
				height: "100%"
			}}
		>
			<div style={loaderStyle}></div>
		</div>
	);
};

export default Loader;
