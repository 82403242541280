import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import "./style.css"

export default function DataGridDemo({ rows, columns }) {
  const getRowId = (row) => row?._id;

  const getRowHeight = () => 90;
  return (
    <Box sx={{ height: rows?.length > 10 ? 700 : (rows?.length * 100) + 200, padding: "0px 10px", marginBottom: "50px", overflowX: 'auto' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 15, 20, 25, 30, 40, 50, 100]}
        slots={{
          toolbar: GridToolbar,
        }}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        disableSelectionOnClick
      />
    </Box>
  );
}