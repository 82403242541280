/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Admin from "../Admin/index";
import { useEffect, useState } from "react";
import DataTable from "examples/Tables/MUI";
import moment from "moment";
import axios from "axios";
import { withAuthContext } from "context/Auth";
import AdminIcon from "assets/images/bg-profile.jpeg";
import MDBadge from "components/MDBadge";
import swal from "sweetalert";
import Loader from "components/Loader";
import ImageCloud from "../../link";

function Task({ ComplainID, ServiceID, Token, CheckToken, AdminRole }) {
	const [AllTask, setAllTask] = useState([]);
	const [errorAllTask, setErrorAllTask] = useState("");

	const [New, setNew] = useState(false);
	const [Loading, setLoading] = useState(false);

	const columns = [
		{
			headerName: "Status",
			field: "Status",
			flex: 1,
			renderCell: ({ row: a }) => (
				<MDBadge
					badgeContent={a?.status}
					color={a?.bgColor || "dark"}
					variant="gradient"
					size="sm"
				/>
			)
		},
		{
			headerName: "assignedTo",
			field: "assignedTo",
			flex: 1,
			renderCell: (params) => (
				<img
					src={
						params?.row?.assignedTo?.profilePicture?.filename
							? `${ImageCloud}/${params?.row?.assignedTo?.profilePicture?.filename}`
							: AdminIcon
					}
					style={{
						width: "50px",
						height: "50px",
						borderRadius: "50px",
						border: "1px solid #000"
					}}
				/>
			)
		},
		{
			headerName: "Assigned To Name",
			field: "assigned",
			flex: 1,
			renderCell: (params) => `${params?.row?.assignedTo?.name}`
		},
		{
			headerName: "Comments By Admin",
			field: "comments",
			flex: 5,
			renderCell: (params) => `${params?.row?.comments || "-"}`
		},
		{ headerName: "complain", field: "complain", flex: 1 },
		{ headerName: "service", field: "service", flex: 1 },
		{
			headerName: "Assigned At",
			field: "createdAt",
			flex: 1,
			renderCell: (params) => `${moment(params?.row?.createdAt).format("DD MMM YYYY \n hh:mm")}`
		}
	];

	const GetTasks = () => {
		if (Token || localStorage.getItem("token")) {
			setLoading(true);
			axios
				.get(
					`${process.env.REACT_APP_PUBLIC_PATH}/${ServiceID ? `TaskInfoService/${ServiceID}` : `TaskInfoComplain/${ComplainID}`
					}`,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoading(false);

					if (res?.data?.status == 200) {
						setAllTask(res?.data?.data);
					} else {
						setErrorAllTask(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoading(false);
					setErrorAllTask(err?.message);
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				GetTasks();
			}, 500);
		}
	};

	useEffect(() => {
		GetTasks();
	}, []);

	useEffect(() => {
		GetTasks();
	}, [ServiceID, ComplainID]);
	const AssignTask = async ({ assignedTo }) => {
		if (Token || localStorage.getItem("token")) {
			const comments = await swal("Add Comments For User:", {
				content: "input",
			});
			setLoading(true);
			const Obj = {
				status: "Pending",
				assignedTo: assignedTo,
				serviceId: null,
				complainId: null,
				comments: comments
			};
			if (ServiceID) {
				Obj.serviceId = ServiceID;
			} else if (ComplainID) {
				Obj.complainId = ComplainID;
			}
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/CreateTask`, Obj, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoading(false);
					if (res?.data?.status == 200) {
						swal({
							text: "Task was assigned to Admin",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: res?.data?.status == 200 ? "success" : "error",
							time: 3000
						});
						setNew(false);
						GetTasks();
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				AssignTask({ assignedTo });
				GetTasks();
			}, 500);
		}
	};

	if (AdminRole == "user") {
		return (
			<MDTypography variant="h6" fontWeight="medium">
				Task Was Assigned To You
			</MDTypography>
		);
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						{New ? "Assign To New Admin" : "Assigned TO"}
					</MDTypography>

					<MDButton
						variant="gradient"
						color="dark"
						onClick={() => {
							setNew(!New);
						}}
					>
						{New ? "Cancel" : "Assign To New Admin"}
					</MDButton>
				</MDBox>
				{New ? (
					<MDBox pt={4} pb={3} px={3}>
						{Loading ? (
							<Loader />
						) : (
							<Admin Task={ServiceID || ComplainID} AssignTask={AssignTask} />
						)}
					</MDBox>
				) : (
					<MDBox pt={3}>
						{Loading ? (
							<Loader />
						) : AllTask.length > 0 ? (
							<DataTable rows={AllTask} columns={columns} />
						) : (
							<MDBox lineHeight={1} textAlign="center">
								<MDTypography
									display="block"
									variant="caption"
									color="text"
									fontWeight="medium"
									fontSize="20px"
								>
									{errorAllTask ? errorAllTask : "This Task is Still Not Assigned To AnyOne"}
								</MDTypography>
							</MDBox>
						)}
					</MDBox>
				)}
			</Card>
		);
	}
}

export default withAuthContext(Task);
