import React, { useEffect, useRef, Fragment, useState } from "react";
import "./ChatMessages.css";
import ChatMessage from "./ChatMessage";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import "./App.css";
import "./Chat.css";
import { withChatContext } from "context/Chat";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";

const ChatMessages = (props) => {
	const {
		minHeight,
		messages,
		opponentImageURL,
		userProfileImageUrl,
		userSpeechBubbleColor,
		translate,
		typings,
		adjustHeader,
		style,
		Chat,
		LoadingChat
	} = props;

	const scrollBarRef = useRef();
	const messagesEndRef = useRef();
	let scrollTop = 0;

	const scrollToBottom = () => {
		scrollBarRef.current && scrollBarRef.current.scrollToBottom();
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const lastMessageFinder = (m1) => {
		const m1Date = new Date(m1).toDateString();
		return ({ createdAt: m2 }) => {
			const m2Date = new Date(m2).toDateString();
			return m1Date === m2Date;
		};
	};

	const getDisplayableDate = (date) => {
		return moment(new Date(date)).format("ll");
	};

	const renderDateIfNeedid = (message, index) => {
		const lastIndex = messages.findIndex(lastMessageFinder(message.createdAt));
		const renderNeeded = lastIndex === index;

		return renderNeeded ? (
			<div className="text-center text-light" style={{ fontSize: 14 }}>
				{getDisplayableDate(message.createdAt)}
			</div>
		) : null;
	};

	const handleScrollFrame = ({ top, scrollHeight }) => {
		top = Math.round(top * 10);

		if (!top && scrollTop && scrollHeight > 300) {
			adjustHeader && adjustHeader(true);
		}

		if (!scrollTop && top && scrollHeight > 350) {
			adjustHeader && adjustHeader(false);
		}

		scrollTop = top;
	};

	const parseTyping = (typings) => {
		const length = typings.length;
		const lastIndex = length - 1;

		if (length > 1) {
			return `${typings.slice(0, lastIndex - 1).join(", ")} and ${typings[lastIndex]} ${translate(
				"are typing"
			)}`;
		} else {
			return `${typings[0]} ${translate("is typing")}`;
		}
	};

	return (
		<Scrollbars
			onScrollFrame={handleScrollFrame}
			renderThumbHorizontal={() => <span />}
			ref={scrollBarRef}
			className="chat-messages-container"
			style={style}
		>
			<div style={{ minHeight, paddingBottom: 20 }}>
				{LoadingChat ? (
					<Loader />
				) : messages?.length ? (
					messages?.map((message, index) => (
						<Fragment key={index}>
							{renderDateIfNeedid(message, index)}
							<ChatMessage
								index={index}
								message={message}
								shouldRenderTimestamp={index === messages.length - 1}
								profileImageURL={opponentImageURL}
								userProfileImageUrl={userProfileImageUrl}
								userSpeechBubbleColor={userSpeechBubbleColor}
								getLanguageString={translate}
							/>
						</Fragment>
					))
				) : (
					<MDTypography variant="h6">No Messages Yet</MDTypography>
				)}
				{typings && typings.length && (
					<div style={{ marginLeft: 20 }} className="opacity-4 padding-top-2">
						{parseTyping(typings)}
					</div>
				)}
				<div style={{ float: "left", clear: "both" }} ref={messagesEndRef}></div>
			</div>
		</Scrollbars>
	);
};

export default withChatContext(ChatMessages);
