// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import PropertyIcon from "assets/images/bg-profile.jpeg";
import { useNavigate, useParams } from "react-router-dom";
import { withPropertyContext } from "context/Property";
import swal from "sweetalert";
import Loader from "components/Loader";
import ImageCloud from "../../link";

function Tables({
	AllProperty,
	errorAllProperty,
	GetProperties,
	AddPropertyToUser,
	serviceId,
	SetPropertyIdService,
	AdminRole,
	LoadingProperty
}) {
	const { userId, propertyId, userPropertyId, carId, ComplainCategoryId } = useParams();
	const navigate = useNavigate();
	const [rows, setRows] = useState([]);

	const columns = [
		{
			headerName: "",
			field: "profilePicture",
			flex: 1,
			renderCell: ({ row: a }) => (
				<img
					src={
						a?.Image[0]?.filename
							? `${ImageCloud}/${a?.Image[0]?.filename}`
							: PropertyIcon
					}
					style={{
						width: "50px",
						height: "50px",
						borderRadius: "50px",
						border: "1px solid #000"
					}}
				/>
			)
		},
		{ headerName: "FlatNo", field: "FlatNo", flex: 1 },
		{ headerName: "Floor", field: "Floor", flex: 1 },
		{ headerName: "Tower", field: "Tower", flex: 1 },
		{ headerName: "Category", field: "Category", flex: 1 },
		{ headerName: "description", field: "description", flex: 1 },
		{
			headerName: "User",
			field: "User",
			flex: 1,
			valueGetter: (params) => `${params.row.User?.length || "-"}`
		},
		{
			headerName: "Service",
			field: "Service",
			flex: 1,
			valueGetter: (params) => `${params.row?.Service?.length || "-"}`
		},
		{
			headerName: "Utility",
			field: "Utility",
			flex: 1,
			valueGetter: (params) => `${params.row?.Utility?.length || "-"}`
		},
		{
			headerName: "Bill",
			field: "Bill",
			flex: 1,
			valueGetter: (params) => `${params.row?.Bill?.length || "-"}`
		},
		{
			headerName: "Review",
			field: "Review",
			flex: 1,
			valueGetter: (params) => `${params.row?.Review?.length || "-"}`
		},
		{
			headerName: "Payment",
			field: "Payment",
			flex: 1,
			valueGetter: (params) => `${params.row?.Payment?.length || "-"}`
		},
		{
			headerName: "Action",
			field: "action",
			flex: 1,
			renderCell: ({ row: a }) => (
				<MDBox>
					{userId && (AdminRole == "admin" || AdminRole == "superadmin") ? (
						<>
							<MDBadge
								badgeContent={"Set Property Owner"}
								color={"dark"}
								variant="gradient"
								size="sm"
								onClick={() => {
									if (userId) {
										AddPropertyToUser({
											userId: userId,
											propertyId: a?._id,
											Type: "owner",
											navigate
										});
									}
								}}
							/>
							<MDBadge
								badgeContent={"Set Property Rentant"}
								color={"dark"}
								variant="gradient"
								size="sm"
								onClick={() => {
									if (userId) {
										AddPropertyToUser({
											userId: userId,
											propertyId: a?._id,
											Type: "rentant",
											navigate
										});
									}
								}}
							/>
						</>
					) : (
						<MDBadge
							badgeContent={
								LoadingProperty ? (
									<Loader />
								) : serviceId || propertyId || userPropertyId || carId || ComplainCategoryId ? (
									"Select Property"
								) : (
									"Edit"
								)
							}
							color={"dark"}
							variant="gradient"
							size="sm"
							onClick={() => {
								if (LoadingProperty) {
									swal({
										text: "Please Let This Task Complete First",
										button: {
											text: "Ok",
											closeModal: true
										},
										icon: "warning",
										time: 3000
									});
								} else if (serviceId) {
									SetPropertyIdService(a?._id);
								} else if (propertyId) {
									navigate(`/UtilityAction/${a?._id}/New`);
								} else if (userPropertyId && (AdminRole == "admin" || AdminRole == "superadmin")) {
									navigate(`/UserAction/${a?._id}/New`);
								} else if (
									carId &&
									(AdminRole == "admin" || AdminRole == "superadmin" || AdminRole == "manager")
								) {
									navigate(`/CarUser/${a?._id}`);
								} else if (ComplainCategoryId) {
									navigate(`/ComplainAction/New/${ComplainCategoryId}/${a?._id}`);
								} else if (AdminRole == "admin" || AdminRole == "superadmin") {
									navigate(`/PropertyInfo/${a?._id}`);
								} else {
									swal({
										text: "There was some Error or You are not allowed for the action",
										button: {
											text: "Ok",
											closeModal: true
										},
										icon: "error",
										time: 3000
									});
								}
							}}
						/>
					)}
				</MDBox>
			)
		}
	];

	useEffect(() => {
		GetProperties();
	}, []);

	const PropertyTableComponent = () => {
		return (
			<Card>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid container style={{ width: "100%" }}>
						<Grid item xs={6}>
							<MDTypography variant="h6" color="white">
								{userId || serviceId || propertyId || userPropertyId
									? "Select Property"
									: "Property"}
							</MDTypography>
						</Grid>
						{!(userId || serviceId || propertyId || userPropertyId) &&
							(AdminRole == "admin" || AdminRole == "superadmin") && (
								<Grid
									item
									xs={6}
									style={{
										display: "flex",
										alignItems: "flex-end",
										alignContent: "flex-end",
										justifyContent: "flex-end"
									}}
								>
									<MDBadge
										badgeContent="New"
										color="dark"
										variant="gradient"
										size="sm"
										onClick={() => navigate(`/PropertyInfo/New`)}
									/>
								</Grid>
							)}
					</Grid>
				</MDBox>

				<MDBox pt={3}>
					{LoadingProperty ? (
						<Loader />
					) : AllProperty.length > 0 ? (
						<DataTable rows={AllProperty} columns={columns} />
					) : (
						<MDBox lineHeight={1} textAlign="center">
							<MDTypography
								display="block"
								variant="caption"
								color="text"
								fontWeight="medium"
								fontSize="20px"
							>
								{errorAllProperty ? errorAllProperty : " No Data Found"}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</Card>
		);
	};
	if (serviceId) return <PropertyTableComponent />;
	else {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<PropertyTableComponent />
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
		);
	}
}

export default withAuthContext(withPropertyContext(Tables));
