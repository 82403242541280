/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import AdminIcon from "assets/images/bg-profile.jpeg";

import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";
import {
	Card,
	Checkbox,
	FormControlLabel,
	Icon,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import ImagePicker from "components/ImagePicker";
import ImageCloud from "../../../../../link";

function Details({ Token, id, data, GetAdmin, CheckToken }) {
	const navigate = useNavigate();
	const [Loading, setLoading] = useState(false);

	const [state, setState] = useState({
		id: id,
		name: "",
		email: "",
		phoneNumber: "",
		password: "",
		Role: "user",
		profilePicture: {},
		TowerNo: "",
		isArchived: false
	});

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};

	useEffect(() => {
		if (id !== "New" && data?._id) {
			setState({
				id: id,
				name: data?.name,
				TowerNo: data?.TowerNo,
				email: data?.email,
				phoneNumber: data?.phoneNumber,
				isArchived: data?.isArchived,
				Role: data?.Role?.toLowerCase(),
				preview: `${ImageCloud}/${data?.profilePicture?.filename}`
			});
		}
	}, [data]);

	const handleSubmit = () => {
		setLoading(true);
		if (state?.id && Token) {
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/${id == "New" ? "Create-Admin" : "Update-Admin"}`, state, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoading(false);
					navigate("/Admin");
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);

					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				handleSubmit();
			}, 500);
		}
	};

	const handleImageSelected = (imageData) => {
		handleChange("profilePicture", imageData);
	};

	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				<MDButton
					variant="gradient"
					color="dark"
					onClick={() => {
						if (!Loading) {
							handleSubmit();
						} else {
							swal({
								text: "Please Let One Task End",
								button: {
									text: "Ok",
									closeModal: true
								},
								icon: "warning",
								time: 3000
							});
						}
					}}
				>
					{id == "New" ? "add" : "update"}
				</MDButton>
			</MDBox>
			<ImagePicker
				onImageSelected={handleImageSelected}
				preview={state?.preview}
				disabled={id !== "New"}
			/>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.name}
								label="name"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("name", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.email}
								label="email"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("email", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.TowerNo}
								label="TowerNo"
								variant="standard"
								fullWidth
								type="number"
								// disabled={id !== "New"}
								onChange={(e) => handleChange("TowerNo", e.target.value)}
							/>
						</MDBox>{" "}
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.phoneNumber}
								label="phoneNumber"
								variant="standard"
								fullWidth
								type="number"
								disabled={id !== "New"}
								onChange={(e) => handleChange("phoneNumber", e.target.value)}
							/>
						</MDBox>{" "}
						{id == "New" && (
							<MDBox pl={2} width="100%">
								<MDInput
									value={state?.password}
									label="password"
									variant="standard"
									fullWidth
									type="password"
									disabled={id !== "New"}
									onChange={(e) => handleChange("password", e.target.value)}
								/>
							</MDBox>
						)}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<InputLabel variant="standard" htmlFor="uncontrolled-native">
								Role
							</InputLabel>
							<Select
								value={state?.Role}
								label={"Role"}
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("Role", e.target.value)}
								style={{ textTransform: "uppercase" }}
							>
								<MenuItem value={"admin"}>admin</MenuItem>
								<MenuItem value={"manager"}>manager</MenuItem>
								<MenuItem value={"user"}>user</MenuItem>
								<MenuItem value={"reception"}>reception</MenuItem>
							</Select>
						</MDBox>
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<InputLabel variant="standard" htmlFor="uncontrolled-native">
								isArchived
							</InputLabel>
							<Select
								value={state?.isArchived}
								label={"isArchived"}
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("isArchived", Boolean(e.target.value))}
								style={{ textTransform: "uppercase" }}
							>
								<MenuItem value={true}>has Left/Removed From Company</MenuItem>
								<MenuItem value={false}>Currently Working</MenuItem>
							</Select>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
}

export default withAuthContext(Details);
