import React, { useEffect, useRef, useState } from "react";
import { Input } from "@mui/material";
import User from "./Users";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { withChatContext } from "context/Chat";

function Chats({ errorAllChat, GetAllChats }) {
	useEffect(() => {
		GetAllChats();
	}, []);

	return (
		<div style={{ height: "80vh", overflow: "scroll", position: "relative", padding: "20px 0px" }}>
			<div
				style={{
					width: "98%",
					position: "sticky",
					top: "1%",
					padding: "10px 20px",
					background: "#FFF",
          borderRadius:"10px",
          zIndex:"1000"
				}}
			>
				<Input placeholder="Search" fullWidth variant="outlined" />
			</div>
			{errorAllChat ? (
				<MDTypography variant="h4">Sorry There Was Some Error</MDTypography>
			) : (
				<User />
			)}
		</div>
	);
}

export default withChatContext(Chats);
