/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withComplainContext } from "context/Complain";
import { useEffect, useState } from "react";
import Loader from "components/Loader";

function ViewBill({ DataGetComplain, AdditionalCharges, Discount, Field, LoadingComplain }) {
	if (LoadingComplain) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h3" fontWeight="medium">
						Bill
					</MDTypography>
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h5" fontWeight="medium">
							Charges
						</MDTypography>
					</MDBox>
					{Object.keys(Field)?.map((a) => {
						return (
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" fontWeight="light">
									{a}
								</MDTypography>
								<MDTypography variant="h6" fontWeight="light">
									{Field?.[a]}
								</MDTypography>
							</MDBox>
						);
					})}
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h5" fontWeight="medium">
							Additional Charges
						</MDTypography>
					</MDBox>
					{Object.keys(AdditionalCharges)?.map((a) => {
						return (
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" fontWeight="light">
									{a}
								</MDTypography>
								<MDTypography variant="h6" fontWeight="light">
									{AdditionalCharges?.[a]}
								</MDTypography>
							</MDBox>
						);
					})}
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							Discount
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{Discount?.Discount}
						</MDTypography>
					</MDBox>
					{Discount?.Discount > 0 && (
						<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
							<MDTypography variant="h6" fontWeight="light">
								Reason For Discount
							</MDTypography>
							<MDTypography variant="h6" fontWeight="light">
								{Discount?.reasonForDiscount}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>

				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							SubTotal
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{DataGetComplain?.Bill?.Total}
						</MDTypography>
					</MDBox>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							Total
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{DataGetComplain?.Bill?.TotalAfterDiscount}
						</MDTypography>
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withComplainContext(ViewBill);
