import React, { createContext, useEffect, useStateServiceOffered } from "react";
import { withAuthContext } from "./Auth";
import axios from "axios";
import swal from "sweetalert";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageCloud from "../link";

export const ServiceOfferedContext = createContext();

export const withServiceOfferedContext = (Component) => (props) =>
	(
		<ServiceOfferedContext.Consumer>
			{(value) => <Component {...value} {...props} />}
		</ServiceOfferedContext.Consumer>
	);

const ServiceOfferedProvider = ({ children, Token, CheckToken }) => {
	const [curId, setCurId] = useState("");
	let { id } = useParams();
	const [DataGetService, setDataGetService] = useState();
	const [LoadingServiceOffered, setLoadingServiceOffered] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setCurId(id);
	}, [id]);

	const GetServiceOffered = () => {
		setLoadingServiceOffered(true);
		axios
			.get(`${process.env.REACT_APP_PUBLIC_PATH}/ServiceOfferedInfo/${curId || id}`)
			.then((res) => {
				setLoadingServiceOffered(false);
				if (res?.data?.status == 200) {
					setDataGetService(res?.data?.data);
				} else {
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				}
			})
			.catch((err) => {
				setLoadingServiceOffered(false);
				swal({
					text: err?.message ? err?.message : "There was some Error",
					button: {
						text: "Ok",
						closeModal: true
					},
					icon: "error",
					time: 3000
				});
			});
	};

	useEffect(() => {
		if (id !== "New") GetServiceOffered();
	}, [id]);

	const [stateServiceOffered, setStateServiceOffered] = useState({
		id: "",
		title: "",
		Fields: [],
		Icon: {},
		preview: ""
	});

	useEffect(() => {
		if (id !== "New") {
			setStateServiceOffered({
				id: id,
				title: DataGetService?.title,
				Fields: DataGetService?.Fields,
				preview: DataGetService?.Icon?.filename
					? `${ImageCloud}/${DataGetService?.Icon?.filename}`
					: ""
			});
		}
	}, [DataGetService]);

	const handleChange = (name, value) => {
		setStateServiceOffered({ ...stateServiceOffered, [name]: value });
	};

	const handleSubmit = () => {
		if (id !== "New") {
			handleChange("id", id);
		}
		setLoadingServiceOffered(true);
		if (Token || localStorage.getItem("token")) {
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${
						id !== "New" ? "Update-ServiceOffered" : "Create-ServiceOffered"
					}`,
					stateServiceOffered,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingServiceOffered(false);
					if (id == "New") {
						setCurId(res?.data?.id);
						navigate(res?.data?.id ? `/ServiceOfferedAction/${res?.data?.id}` : `/ServiceOffered`);
						// window.location.reload(false);
					}
					setStateServiceOffered({
						id: id,
						title: "",
						Fields: [],
						Icon: {},
						preview: ""
					});
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingServiceOffered(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingServiceOffered(false);
			setTimeout(() => {
				CheckToken();
				handleSubmit();
			}, 500);
		}
	};

	const [AddField, setAddField] = useState(false);

	useEffect(() => {
		if (id == "New") setAddField(true);
	}, [id]);

	return (
		<ServiceOfferedContext.Provider
			value={{
				handleChange,
				handleSubmit,
				stateServiceOffered,
				GetServiceOffered,
				DataGetService,
				AddField,
				setAddField,
				id,
				LoadingServiceOffered
			}}
		>
			{children}
		</ServiceOfferedContext.Provider>
	);
};

export default withAuthContext(ServiceOfferedProvider);
