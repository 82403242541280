import React from "react";
import { Grid, List } from "@mui/material";
import UserImg from "../../../../../assets/images/Bg.jpeg";
import { withChatContext } from "context/Chat";
import MDTypography from "components/MDTypography";
import ImageCloud from "../../../../../link";

const ChatAppHeader = ({ Chat }) => {
  const styleCenter = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  };

  const titleStyle = {
    whiteSpace: "nowrap",
    fontWeight: 900,
    fontSize: "20px",
  };

  return (
    <List>
      <Grid
        style={{
          border: "2px solid #B0B0B0",
          borderRadius: "10px",
          margin: "5px 0px",
          padding: "10px 5px",
          background: "#FFF",
        }}
      >
        <Grid container>
          {/*icon*/}
          <Grid style={styleCenter} container>
            <Grid
              sm={1.5}
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
              }}
            >
              <img
                src={
                  Chat?.Complain?.ComplainCategory?.Icon?.filename
                    ? `${ImageCloud}/${Chat?.Complain?.ComplainCategory?.Icon?.filename}`
                    : UserImg
                }
                style={{ width: "50px", height: "50px", borderRadius: "100%" }}
              />
            </Grid>
            <Grid
              sm={6}
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                width: "100%",
                textAlign: "left",
              }}
            >
              <div style={titleStyle}>
                {Chat?.Complain?.ComplainCategory?.title}
              </div>
              <MDTypography variant="caption">{Chat?._id}</MDTypography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <img
                  src={
                    Chat?.User?.profilePicture?.filename
                      ? `${ImageCloud}/${Chat?.User?.profilePicture?.filename}`
                      : UserImg
                  }
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "100%",
                    marginRight: "10px"
                  }}
                  alt="user"
                />
                <MDTypography
                  variant="caption"
                  style={{
                    color: "green",
                    fontWeight: "500",
                  }}
                >
                  {Chat?.User?.name}
                </MDTypography>
              </div>
            </Grid>
            <Grid
              sm={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignContent: "flex-end",
                width: "100%",
                textAlign: "right",
              }}
            >
              <MDTypography variant="caption">
                FlatNo:- {Chat?.Property?.FlatNo}
              </MDTypography>
              <MDTypography variant="caption">
                Tower:- {Chat?.Property?.Tower}
              </MDTypography>
              <MDTypography variant="caption">
                Floor:- {Chat?.Property?.Floor}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </List>
  );
};

export default withChatContext(ChatAppHeader);
