/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import UserIcon from "assets/images/bg-profile.jpeg";

import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";
import {
  Card,
  Checkbox,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import ImagePicker from "components/ImagePicker";
import ImageCloud from "../../../../../link";

function Details({ Token, id, data, GetUtility, propertyId, CheckToken }) {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [state, setState] = useState({
    id: id,
    title: "",
    description: "",
    status: "",
    Total: "",
    Property: "",
    Image: [],
    preview: [],
  });

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (id !== "New" && data?._id) {
      setState({
        id: data?._id,
        ...state,
        ...data,
      });

      data?.Image?.map((a) => {
        const Img = [];
        Img.push({
          data: `${ImageCloud}/${a?.filename}`,
          id: a,
        });
        handleChange("preview", Img);
      });
    }
  }, [data]);

  useEffect(() => {
    if (propertyId) {
      handleChange("Property", propertyId);
    }
  }, [propertyId]);

  const handleSubmit = () => {
    if (state?.id && Token) {
      axios
        .post(`${process.env.REACT_APP_PUBLIC_PATH}/Create-Utility`, state, {
          headers: {
            Authorization: Token
              ? `${Token}`
              : `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (id == "New") {
            navigate("/Utility");
          } else {
            GetUtility();
          }
          swal({
            text: res?.data?.message,
            button: {
              text: "Ok",
              closeModal: true,
            },
            icon: res?.data?.status == 200 ? "success" : "error",
            time: 3000,
          });
        })
        .catch((err) => {
          swal({
            text: err?.response?.data?.message
              ? err?.response?.data?.message
              : "There was some Error",
            button: {
              text: "Ok",
              closeModal: true,
            },
            icon: "error",
            time: 3000,
          });
        });
    } else {
      setTimeout(() => {
        CheckToken();
        handleSubmit();
      }, 500);
    }
  };

  const handleImageSelected = (imageData) => {
    const arr = state?.Image.length > 0 ? [...state?.Image] : [];
    arr.push(imageData);
    handleChange("Image", arr);
  };

  const ImageArr = id !== "New" ? state?.preview : state?.Image;
  return (
    <Card id="delete-account">
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Basic Details
        </MDTypography>
        {id == "New" && (
          <MDButton
            variant="gradient"
            color="dark"
            onClick={() => handleSubmit()}
          >
            add
          </MDButton>
        )}
      </MDBox>
      {id == "New" && <ImagePicker onImageSelected={handleImageSelected} />}

      <MDBox
        mb={2}
        pt={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="80%"
        overflowX="scroll"
      >
        {ImageArr?.map((a) => (
          <img
            src={a?.data}
            alt="Selected"
            className="selected-image"
            style={{
              width: id !== "New" ? "100px" : "50px",
              height: id !== "New" ? "100px" : "50px",
            }}
          />
        ))}
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox
            mb={2}
            pt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <MDBox mb={2} width="100%">
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                status
              </InputLabel>
              <Select
                value={state?.status}
                label="status"
                variant="standard"
                fullWidth
                disabled={id !== "New"}
                onChange={(e) => handleChange("status", e.target.value)}
                style={{ textTransform: "uppercase" }}
              >
                <MenuItem value={"active"}>active</MenuItem>
                <MenuItem value={"inactive"}>inactive</MenuItem>
                <MenuItem value={"canceled"}>canceled</MenuItem>
                <MenuItem value={"delayed"}>delayed</MenuItem>
                <MenuItem value={"paid"}>paid</MenuItem>
              </Select>
            </MDBox>
            <MDBox pl={2} width="100%">
              <MDInput
                value={state?.title}
                label="title"
                variant="standard"
                fullWidth
                disabled={id !== "New"}
                onChange={(e) => handleChange("title", e.target.value)}
              />
            </MDBox>
          </MDBox>
          <MDBox
            pt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <MDBox pr={2} width="100%">
              <MDInput
                value={state?.Total}
                label="Total"
                variant="standard"
                fullWidth
                type="number"
                disabled={id !== "New"}
                onChange={(e) => handleChange("Total", e.target.value)}
              />
            </MDBox>
            <MDBox pl={2} width="100%">
              <MDInput
                value={state?.description}
                label="description"
                variant="standard"
                fullWidth
                disabled={id !== "New"}
                onChange={(e) => handleChange("description", e.target.value)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default withAuthContext(Details);
