import { useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Button } from "@mui/material";
import { Delete, Send } from "@mui/icons-material";
import MDTypography from "components/MDTypography";

const VoiceNote = ({ handleChange, isVoiceRecording, sendRecording }) => {
  const [File, setFile] = useState({});
  const Ref = useRef(null);
  function generateRandomFileName(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }

    return result;
  }

  const recorderControls = useAudioRecorder();

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64data = event.target.result;
      const Obj = {
        data: base64data,
        name: generateRandomFileName(20) + ".webm",
        type: "audio/webm",
      };
      handleChange(Obj);
      setFile(Obj);
    };
    reader.readAsDataURL(blob);
    if (Ref.current) {
      Ref.current.src = url;
      Ref.current.controls = true;
    }
  };

  useEffect(() => {
    if (isVoiceRecording) recorderControls?.startRecording();
  }, [isVoiceRecording]);

  useEffect(() => {
    if (File?.name) {
      sendRecording();
    }
  }, [File]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        gap: 10,
        margin: "20px 0px",
      }}
    >
      <audio ref={Ref} />
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
      />
      <Delete
        style={{
          background: "green",
          color: "#FFF",
          width: "40px",
          height: "40px",
          padding: "10px",
          borderRadius: "25%",
          margin: "0 10px",
        }}
        onClick={() => {
          handleChange({});
          sendRecording();
        }}
      />
      <Button
        endIcon={
          <Send
            style={{
              background: "green",
              color: "#FFF",
              width: "40px",
              height: "40px",
              padding: "10px",
              borderRadius: "25%",
              margin: "0 10px",
            }}
          />
        }
        onClick={async () => {
          recorderControls.stopRecording();
        }}
        style={{
          border: "2px solid green",
          padding: "5px",
          width: "200px",
          margin: "5px 10px",
        }}
      >
        <MDTypography variant="h5" style={{ paddingRight: "10px" }}>
          Send
        </MDTypography>
      </Button>
    </div>
  );
};

export default VoiceNote;
