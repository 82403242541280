import React, { createContext, useEffect, useRef, useState } from "react";
import { withAuthContext } from "./Auth";
import axios from "axios";
import swal from "sweetalert";
import UserImg from "../assets/images/Bg.jpeg";
import { io } from "socket.io-client";
import ImageCloud from "../link";

export const ChatContext = createContext();

export const withChatContext = (Component) => (props) =>
	<ChatContext.Consumer>{(value) => <Component {...value} {...props} />}</ChatContext.Consumer>;

const socket = io(`${process.env.REACT_APP_PUBLIC_PATH}`, {
	pingInterval: 5000,
	pingTimeout: 10000,
	reconnectionAttempts: 3,
	debug: true
});

const ChatProvider = ({ children, Token, CheckToken }) => {
	const [CurrentChat, setCurrentChat] = useState("");

	const [messages, setMessages] = useState([]);
	const [isGettingMessages, setisGettingMessages] = useState(false);

	const [AllChat, setAllChat] = useState([]);
	const [errorAllChat, setErrorAllChat] = useState("");

	const [Chat, setChat] = useState([]);
	const [errorChat, setErrorChat] = useState("");

	const currentChatRef = useRef(CurrentChat);

	const [LoadingChat, setLoadingChat] = useState(false);

	useEffect(() => {
		currentChatRef.current = CurrentChat;
	}, [CurrentChat]);

	const GetAllChats = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingChat(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllChatAdmin`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then(async (res) => {
					setLoadingChat(false);

					if (res?.data?.status == 200) {
						setAllChat([]);
						const arr = res?.data?.data
							.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
							.filter((a) => a?.status == "active");
						setAllChat(arr);
					} else {
						setErrorAllChat(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoadingChat(false);
					setErrorAllChat(err?.message);
				});
		} else {
			setLoadingChat(false);
			setTimeout(() => {
				CheckToken();
				GetAllChats();
			}, 500);
		}
	};

	const GetChatInfo = (id) => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/ChatInfo/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingChat(false);

					if (res?.data?.status == 200) {
						setChat(res?.data?.data);
					} else {
						setErrorChat(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoadingChat(false);
					setErrorChat(err?.message);
				});
		} else {
			setLoadingChat(false);
			setTimeout(() => {
				CheckToken();
				GetChatInfo(id);
			}, 500);
		}
	};

	const GetMessages = (id) => {
		if (Token || localStorage.getItem("token")) {
			setisGettingMessages(true);

			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllMessages/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingChat(false);

					if (res?.data?.status == 200) {
						setMessages([]);
						let arr = [];

						res?.data?.data?.forEach((a) => {
							const Obj = {
								id: a?._id,
								loading: false,
								image: null,
								createdAt: new Date(a?.created_at),
								gif: null,
								emoji: null,
								text: a?.text,
								ChatID: a?.Chat
							};
							if (a?.VoiceNote?.filename) {
								Obj.VoiceNote = `${ImageCloud}/${a?.VoiceNote?.filename}`;
							}

							if (a?.Media?.filename) {
								Obj.image = `${ImageCloud}/${a?.Media?.filename}`;
							}

							if (a?.User) {
								Obj.opponent = {
									name: Chat?.User?.name,
									imageUrl: Chat?.User?.profilePicture?.filename
										? `${ImageCloud}/${Chat?.User?.profilePicture?.filename}`
										: UserImg
								};
							}
							arr.push(Obj);
							const uniqueIds = {};
							const uniqueObjects = arr.filter((obj) => {
								if (!uniqueIds[obj.id] && obj.ChatID == id) {
									uniqueIds[obj.id] = true;
									return true;
								}
								return false;
							});

							setMessages(uniqueObjects);

							setTimeout(() => {
								setisGettingMessages(false);
							}, 5000);
						});
					} else {
						setErrorChat(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoadingChat(false);
					setErrorChat(err?.message);
				});
		} else {
			setLoadingChat(false);
			setTimeout(() => {
				CheckToken();
				GetMessages(id);
			}, 500);
		}
	};

	const SendMessage = async ({ text, VoiceNote, Media }) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingChat(true);

			const Obj = {
				text: text,
				Chat: await CurrentChat
			};
			const SocketObj = { room: await CurrentChat, message: text, user: false };
			if (VoiceNote?.name) {
				Obj.VoiceNote = VoiceNote;
				Obj.text = "";
				SocketObj.text = "Voice Note Incoming .....";
			}
			if (Media?.name) {
				Obj.Media = Media;
				Obj.text = "";
				SocketObj.text = "Media Incoming ....";
			}
			socket.emit("message", SocketObj);

			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Create-Message`, Obj, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingChat(false);
					if (res?.data?.status != 200) {
						setErrorChat(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoadingChat(false);
					setErrorChat(err?.message);
				});
		} else {
			setLoadingChat(false);
			setTimeout(() => {
				CheckToken();
				SendMessage({ text });
			}, 500);
		}
	};

	useEffect(() => {
		GetAllChats();
	}, []);

	useEffect(() => {
		setMessages([]);
		GetMessages(CurrentChat);
		GetChatInfo(CurrentChat);
	}, [CurrentChat]);

	useEffect(() => {
		if (AllChat.length > 0 && !CurrentChat) {
			setCurrentChat(AllChat?.[0]?._id);
		}
	}, [CurrentChat, AllChat]);

	const CONNECT_RETRY_DELAY = 60000;
	const MAX_CONNECTION_ATTEMPTS = 10;

	function handleSocketConnection() {
		let connectionAttempts = 0;

		function connectSocket() {
			if (!socket.connected && connectionAttempts < MAX_CONNECTION_ATTEMPTS) {
				setTimeout(() => {
					console.log("Time: - ", new Date(), "!connected. Attempting to reconnect...");
					socket.connect();
					connectionAttempts += 1;
					connectSocket();
				}, CONNECT_RETRY_DELAY);
			} else if (connectionAttempts >= MAX_CONNECTION_ATTEMPTS) {
				console.error("Maximum connection attempts reached. Disconnecting from all rooms.");
				socket.emit("disconnectFromAllRooms");
			}
		}

		socket.on("connect_error", (error) => {
			setTimeout(() => {
				console.log("Time: - ", new Date(), "connect_error");
				connectSocket();
			}, CONNECT_RETRY_DELAY);
		});

		socket.on("connect_timeout", (timeout) => {
			console.error("Socket connection timeout:", timeout);

			setTimeout(() => {
				console.log("Time: - ", new Date(), "connect_timeout");
				connectSocket();
			}, CONNECT_RETRY_DELAY);
		});

		socket.on("disconnect", (reason) => {
			setTimeout(() => {
				console.log("Time: - ", new Date(), "disconnect");
				connectSocket();
			}, CONNECT_RETRY_DELAY);
		});

		connectSocket();
	}

	useEffect(() => {
		handleSocketConnection();
	}, []);

	function JoinSocket() {
		if (AllChat?.length > 0) {
			AllChat?.forEach((a) => {
				socket.emit("leaveRoom", { room: a?._id });
				setTimeout(() => {
					socket.emit("room", { room: a?._id });
				}, 3000);
			});
			return;
		}
	}

	useEffect(() => {
		handleSocketConnection();
		JoinSocket();
		return;
	}, [AllChat, socket.connected]);

	function alignArrayById(arr, idToMove) {
		const indexToMove = arr.findIndex((obj) => obj._id === idToMove);

		if (indexToMove !== -1) {
			const elementToMove = arr[indexToMove];
			arr.splice(indexToMove, 1);
			arr.unshift(elementToMove);
		}

		return arr;
	}

	async function handleMessageSocket(data) {
		const Obj = {
			id: "newMessage",
			loading: false,
			image: null,
			createdAt: new Date(),
			gif: null,
			emoji: null,
			text: data?.message,
			ChatID: data?.room
		};
		if (data?.user) {
			Obj.opponent = {
				name: "",
				imageUrl: ""
			};
		}
		if (currentChatRef.current == data?.room) {
			setMessages((prevMessages) => [...prevMessages, Obj]);
		} else {
			const arr = alignArrayById(AllChat, currentChatRef.current);
			setAllChat(arr);
		}

		if (!isGettingMessages) {
			setTimeout(() => {
				GetMessages(currentChatRef.current);
			}, 5000);
			if (currentChatRef.current != data?.room) {
				GetAllChats();
			}
		}
	}

	useEffect(() => {
		socket.on("allroomsclosed", JoinSocket());
		return () => {
			socket.off("allroomsclosed", JoinSocket());
		};
	}, []);

	return (
		<ChatContext.Provider
			value={{
				AllChat,
				errorAllChat,
				GetAllChats,
				GetChatInfo,
				Chat,
				setChat,
				errorChat,
				setErrorChat,
				messages,
				setMessages,
				SendMessage,
				socket,
				handleMessageSocket,
				GetMessages,
				CurrentChat,
				setCurrentChat,
				LoadingChat,
				setLoadingChat
			}}
		>
			{children}
		</ChatContext.Provider>
	);
};

export default withAuthContext(ChatProvider);
