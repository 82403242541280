/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceContext } from "context/Service";
import { useState } from "react";
import swal from "sweetalert";
import Loader from "components/Loader";

function Payment({
	handleSubmitPayment,
	serviceId,
	DataGetService,
	statePayment,
	handleChangePayment,
	LoadingService
}) {
	if (LoadingService) {
		return <Loader />;
	}else{
    return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						Payment
					</MDTypography>
					{!DataGetService?.Payment?._id && (
						<MDButton
							variant="gradient"
							color="dark"
							onClick={() => {
								if (
									serviceId !== "New" &&
									DataGetService?.Bill?.TotalAfterDiscount == statePayment?.Amount
								) {
									handleSubmitPayment();
								} else {
									swal({
										text: `Please Pay the Full Amount ${DataGetService?.Bill?.TotalAfterDiscount}`,
										button: {
											text: "Ok",
											closeModal: true
										},
										icon: "error",
										time: 3000
									});
								}
							}}
						>
							{DataGetService?.Payment?._id ? "update" : "Add"} Payment
						</MDButton>
					)}
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								value={statePayment?.Amount}
								label={"Amount"}
								variant="standard"
								type="number"
								fullWidth
								disabled={DataGetService?.Payment?._id}
								onChange={(e) => handleChangePayment("Amount", e.target.value)}
							/>
						</MDBox>
						<MDBox mb={2}>
							<InputLabel variant="standard" htmlFor="uncontrolled-native">
								Type
							</InputLabel>
							<Select
								value={statePayment?.Type}
								label={"Type"}
								variant="standard"
								fullWidth
								disabled={DataGetService?.Payment?._id}
								onChange={(e) => handleChangePayment("Type", e.target.value)}
								style={{ textTransform: "uppercase" }}
							>
								<MenuItem value={"cash"}>cash</MenuItem>
								<MenuItem value={"cheque"}>cheque</MenuItem>
								<MenuItem value={"card"}>card</MenuItem>
							</Select>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								value={statePayment?.description}
								label={"description"}
								variant="standard"
								fullWidth
								disabled={DataGetService?.Payment?._id}
								onChange={(e) => handleChangePayment("description", e.target.value)}
							/>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		);
  }
}

export default withServiceContext(Payment);
