/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withComplainContext } from "context/Complain";
import { useState } from "react";
import Loader from "components/Loader";

function Bill({
	handleSubmitBill,
	ComplainId,
	DataGetComplain,
	AdditionalCharges,
	handleAddCharges,

	Field,
	handleFields,

	Discount,
	handleDiscount,

	LoadingComplain
}) {
	const [name, setName] = useState("");
	const [nameField, setNameField] = useState("");
	if (LoadingComplain) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						Bill
					</MDTypography>

					<MDButton
						variant="gradient"
						color="dark"
						onClick={() => {
							if (ComplainId !== "New") {
								handleSubmitBill();
							}
						}}
					>
						{DataGetComplain?.Bill?._id ? "update" : "generate"} Bill
					</MDButton>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDInput
							value={Discount?.Discount}
							label={"Discount"}
							variant="standard"
							fullWidth
							onChange={(e) => handleDiscount("Discount", e.target.value)}
						/>
						<MDInput
							value={Discount?.reasonForDiscount}
							label={"reasonForDiscount"}
							variant="standard"
							fullWidth
							onChange={(e) => handleDiscount("reasonForDiscount", e.target.value)}
						/>
						<MDInput
							value={Discount?.Remarks}
							label={"Remarks"}
							variant="standard"
							fullWidth
							onChange={(e) => handleDiscount("Remarks", e.target.value)}
						/>
					</MDBox>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="medium">
							Details
						</MDTypography>
					</MDBox>
					<MDBox component="form" role="form">
						<MDBox mb={2} display="flex" justifyContent="center" alignItems="center">
							<MDInput
								style={{ width: "75%", marginRight: "5%" }}
								value={nameField}
								label={nameField}
								variant="standard"
								fullWidth
								onChange={(e) => setNameField(e.target.value)}
							/>
							<MDButton
								variant="gradient"
								color="dark"
								onClick={() => {
									handleFields(nameField, 0);
									setNameField("");
								}}
							>
								Add Fields
							</MDButton>
						</MDBox>
						{Object.keys(Field)?.map((a) => {
							return (
								<MDBox mb={2}>
									<MDInput
										value={Field?.[a]}
										label={a}
										variant="standard"
										fullWidth
										onChange={(e) => handleFields(a, e.target.value)}
									/>
								</MDBox>
							);
						})}
					</MDBox>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="medium">
							Additional Charges
						</MDTypography>
					</MDBox>
					<MDBox component="form" role="form">
						<MDBox mb={2} display="flex" justifyContent="center" alignItems="center">
							<MDInput
								style={{ width: "75%", marginRight: "5%" }}
								value={name}
								label={name}
								variant="standard"
								fullWidth
								onChange={(e) => setName(e.target.value)}
							/>
							<MDButton
								variant="gradient"
								color="dark"
								onClick={() => {
									handleAddCharges(name, 0);
									setName("");
								}}
							>
								Add Charges
							</MDButton>
						</MDBox>
						{Object.keys(AdditionalCharges)?.map((a) => {
							return (
								<MDBox mb={2}>
									<MDInput
										value={AdditionalCharges?.[a]}
										label={a}
										variant="standard"
										fullWidth
										onChange={(e) => handleAddCharges(a, e.target.value)}
									/>
								</MDBox>
							);
						})}
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withComplainContext(Bill);
