// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import UtilityIcon from "assets/images/bg-profile.jpeg";
import { useNavigate } from "react-router-dom";
import ImageCloud from "../../link";

function Tables({ Token, CheckToken }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");


  const columns = [
    {
      headerName: "", field: "Image", flex: 1, renderCell: ({ row: a }) => <img
        src={
          a?.Image[0]?.filename
            ? `${ImageCloud}/${a?.Image[0]?.filename}`
            : UtilityIcon
        }
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50px",
          border: "1px solid #000",
        }}
      />
    },
    { headerName: "title", field: "title", flex: 1 },
    { headerName: "description", field: "description", flex: 1 },
    { headerName: "status", field: "status", flex: 1 },
    { headerName: "Total", field: "Total", flex: 1 },
    { headerName: "Property", field: "Property", flex: 1, valueGetter: ({ row: a }) => `${a?.Property?.FlatNo || "-"}` },
    {
      headerName: "Action", field: "action", flex: 1, renderCell: ({ row: a }) => <MDBox>
        <MDBadge
          badgeContent="View"
          color={"dark"}
          variant="gradient"
          size="sm"
          onClick={() =>
            navigate(`/UtilityAction/${a?.Property?._id}/${a?._id}`)
          }
        />
      </MDBox>
    },
  ];


  const GetUtilitys = () => {
    if (Token || localStorage.getItem("token")) {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllUtility`, {
          headers: {
            Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res?.data?.status == 200) {
            setData(res?.data?.data.sort((a, b) => {
              const timeA = (new Date() - new Date(a?.created_at)) / (1000 * 60 * 60);
              const timeB = (new Date() - new Date(b?.created_at)) / (1000 * 60 * 60);

              return timeA - timeB;
            }));
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
        });
    } else {
      setTimeout(() => {
        CheckToken();
        GetUtilitys();
      }, 500);
    }
  };

  useEffect(() => {
    GetUtilitys();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container style={{ width: "100%" }}>
                  <Grid item xs={6}>
                    <MDTypography variant="h6" color="white">
                      Utility
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <MDBadge
                      badgeContent="New"
                      color="dark"
                      variant="gradient"
                      size="sm"
                      onClick={() => navigate(`/UtilityProperty/New`)}
                    />
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3}>
                {data.length > 0 ? (
                  <DataTable
                    rows={data}
                    columns={columns}
                  />
                ) : (
                  <MDBox lineHeight={1} textAlign="center">
                    <MDTypography
                      display="block"
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      fontSize="20px"
                    >
                      {error ? error : " No Data Found"}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default withAuthContext(Tables);
