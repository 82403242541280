import React, { useEffect, useState } from "react";
import { Button, Chip, Grid, List } from "@mui/material";
import UserImg from "../../../../../assets/images/Bg.jpeg";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { withChatContext } from "context/Chat";
import { withAuthContext } from "context/Auth";
import Loader from "components/Loader";
import ImageCloud from "../../../../../link";

const styleCenter = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "60px"
};

const descriptionStyles = {
	color: "#97969B",
	fontWeight: "lighter",
	paddingBottom: 5,
	textAlign: "justify",
	overflow: "hidden"
};

const titleStyle = {
	paddingBottom: 5,
	whiteSpace: "nowrap",
	fontWeight: "bolder"
};

const ListDemo = ({ AllChat, LoadingChat, CurrentChat, setCurrentChat }) => {
	return (
		<List hover autoScroll={true}>
			{LoadingChat ? (
				<Loader />
			) : (
				AllChat.map((item, index) => {
					return (
						<Grid
							key={item._id}
							index={index}
							style={
								CurrentChat == item._id
									? {
											margin: "10px 5px",
											border: "1px solid #84C857",
											borderLeftWidth: "8px"
									  }
									: { margin: "10px 5px", border: "none !important" }
							}
							onClick={() => {
								setCurrentChat(item?._id);
							}}
						>
							<Grid container>
								{/*icon*/}
								<Grid lg={2} md={2} sm={4} xs={4} style={styleCenter}>
									<img
										src={
											item?.Complain?.ComplainCategory?.Icon?.filename
												? `${ImageCloud}/${item?.Complain?.ComplainCategory?.Icon?.filename}`
												: UserImg
										}
										style={{
											width: "50px",
											height: "50px",
											borderRadius: "100%"
										}}
										alt="user"
									/>
								</Grid>
								{/*base info*/}
								<Grid
									lg={6}
									md={8}
									sm={8}
									style={{
										...styleCenter,
										flexDirection: "column",
										alignItems: "flex-start",
										overflow: "hidden"
									}}
								>
									<MDTypography variant="h6" style={titleStyle}>
										{item?.Complain?.ComplainCategory?.title}
									</MDTypography>
									<MDTypography variant="caption" style={descriptionStyles}>
										Flat No:- {item?.Property?.FlatNo}
										{`\t`}
										Tower No:- {item?.Property?.Tower}
										{`\t`}
										Floor No:- {item?.Property?.Floor}
									</MDTypography>
								</Grid>
								{/*peak AllChat*/}
								<Grid lg={3} md={3} sm={3} style={styleCenter}>
									<div
										style={{
											textAlign: "right",
											display: "flex",
											flexDirection: "row",
											width: "100%",
											alignItems: "flex-end",
											alignContent: "flex-end",
											justifyContent: "flex-end"
										}}
									>
										<div style={{ display: "flex", flexDirection: "row" }}>
											<img
												src={
													item?.User?.profilePicture?.filename
														? `${ImageCloud}/${item?.User?.profilePicture?.filename}`
														: UserImg
												}
												style={{
													width: "20px",
													height: "20px",
													borderRadius: "100%",
													marginRight: "10px"
												}}
												alt="user"
											/>
											<MDTypography
												variant="caption"
												style={{
													color: "green",
													fontWeight: "500"
												}}
											>
												{item?.User?.name}
											</MDTypography>
										</div>
										{item.notifications && <Chip label={item.notifications} />}
									</div>
								</Grid>
							</Grid>
						</Grid>
					);
				})
			)}
		</List>
	);
};

export default withAuthContext(withChatContext(ListDemo));
