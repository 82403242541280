/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import DetailsForm from "./components/Details";
import Utility from "./components/Utility";
import Services from "./components/Services";
import Transactions from "./components/Transactions";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";

function Billing({ Token, CheckToken }) {
  const { id } = useParams();
  const [data, setData] = useState();
  const [error, setError] = useState("");

  const GetProperty = () => {
    if (Token || localStorage.getItem("token"))  {
      axios
        .get(`${process.env.REACT_APP_PUBLIC_PATH}/PropertyInfo/${id}`, {
          headers: {
            Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res?.data?.status == 200) {
            setData(res?.data?.data);
          } else {
            setError(res?.data?.message);
          }
        })
        .catch((err) => {
          setError(err?.message);
        });
    } else {
      setTimeout(() => {
        CheckToken();

        GetProperty();
      }, 500);
    }
  };

  useEffect(() => {
    if (id !== "New") GetProperty();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        {id !== "New" ? (
          <>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} xl={6}>
                      <MasterCard
                        number={data?.description || "Property Name"}
                        holder={data?.FlatNo || "1"}
                        expires={data?.Tower || "1"}
                        background={
                          "assets/images/illustrations/pattern-tree.svg"
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        icon="account_balance"
                        title="Bill"
                        description="Belong Interactive"
                        value="+$2000"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={3}>
                      <DefaultInfoCard
                        icon="paypal"
                        title="Payments"
                        description="Freelance Payment"
                        value="$455.00"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DetailsForm
                        id={id}
                        data={data}
                        GetProperty={GetProperty}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Utility Arr={data?.Utility} />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <Services data={data?.Service} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Transactions />
                </Grid>
              </Grid>
            </MDBox>
          </>
        ) : (
          <DetailsForm id={id} />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default withAuthContext(Billing);
