/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import UserIcon from "assets/images/bg-profile.jpeg";

import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";
import { Card, Checkbox, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import MDBadge from "components/MDBadge";
import { withPropertyContext } from "context/Property";
import ImagePicker from "components/ImagePicker";
import ImageCloud from "../../../../../link";

const columns = [
	{ Header: "", accessor: "profilePicture", width: "45%", align: "left" },
	{ Header: "name", accessor: "name", width: "45%", align: "left" },
	{ Header: "email", accessor: "email", align: "left" },
	{ Header: "phoneNumber", accessor: "phoneNumber", align: "center" },
	{ Header: "flatNo", accessor: "flatNo", width: "45%", align: "left" },
	{ Header: "otp", accessor: "otp", align: "left" },
	{ Header: "notifications", accessor: "notifications", align: "center" },
	{
		Header: "TermsAndConditions",
		accessor: "TermsAndConditions",
		align: "center"
	},
	{
		Header: "verifiedByAdmin",
		accessor: "verifiedByAdmin",
		width: "45%",
		align: "left"
	},
	{ Header: "isVerified", accessor: "isVerified", align: "left" },
	{ Header: "Action", accessor: "action", align: "center" }
];

function Details({ Token, id, data, GetProperty, RemovePropertyToUser, CheckToken }) {
	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const [error, setError] = useState("");
	const [Loading, setLoading] = useState(false);

	useEffect(() => {
		if (id !== "New") {
			const arr = [];
			data?.User?.map((a) => {
				arr.push({
					profilePicture: (
						<img
							src={a?.profilePicture?.filename ? a?.profilePicture?.filename : UserIcon}
							style={{
								width: "50px",
								height: "50px",
								borderRadius: "50px",
								border: "1px solid #000"
							}}
						/>
					),
					name: (
						<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
							{a?.name}
						</MDTypography>
					),
					email: (
						<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
							{a?.email}
						</MDTypography>
					),
					phoneNumber: (
						<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
							{a?.phoneNumber}
						</MDTypography>
					),
					flatNo: (
						<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
							{a?.Property?.description ? a?.Property?.description : a?.flatNo}
						</MDTypography>
					),
					otp: (
						<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
							{a?.otp}
						</MDTypography>
					),
					notifications: <Checkbox defaultChecked={a?.notifications} disabled />,
					TermsAndConditions: <Checkbox defaultChecked={a?.TermsAndConditions} disabled />,
					verifiedByAdmin: <Checkbox defaultChecked={a?.verifiedByAdmin} disabled />,
					isVerified: <Checkbox defaultChecked={a?.isVerified} disabled />,
					action: (
						<MDBox>
							<MDBadge
								badgeContent="Remove Property"
								color={"warning"}
								variant="gradient"
								size="sm"
								onClick={() =>
									RemovePropertyToUser({
										userId: a?._id,
										propertyId: id,
										GetUsers: GetProperty
									})
								}
							/>
						</MDBox>
					)
				});
			});
			setRows(arr);
		}
	}, [data]);

	const [state, setState] = useState({
		id: id,
		description: "",
		FlatNo: "",
		Floor: "",
		Tower: "",
		Category: "3BED",
		Image: [],
		preview: []
	});

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};
	useEffect(() => {
		if (data?._id) {
			setState({
				id: id,
				description: data?.description,
				FlatNo: data?.FlatNo,
				Floor: data?.Floor,
				Tower: data?.Tower,
				Category: data?.Category,
				preview: data?.Image
			});
		}
	}, [data]);

	const handleSubmit = () => {
		if (state?.id && Token) {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${
						id !== "New" ? "Update-Property" : "Create-Property"
					}`,
					state,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoading(false);

					if (id == "New") {
						navigate("/Property");
					} else {
						window.location.reload();
						GetProperty();
					}
					setState({
						id: id,
						description: "",
						FlatNo: "",
						Floor: "",
						Tower: "",
						Category: "",
						Image: [],
						preview: []
					});
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				GetProperty();
				handleSubmit();
			}, 500);
		}
	};

	const handleImageSelected = (imageData) => {
		const arr = state?.Image?.length >= 0 ? [...state?.Image] : [];
		arr.push(imageData);
		handleChange("Image", arr);
	};

	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				<MDButton
					variant="gradient"
					color="dark"
					onClick={() => {
						if (!Loading) {
							handleSubmit();
						} else {
							swal({
								text: "Please Let This Task Complete First",
								button: {
									text: "Ok",
									closeModal: true
								},
								icon: "warning",
								time: 3000
							});
						}
					}}
				>
					{id !== "New" ? "update" : "add"}
				</MDButton>
			</MDBox>

			<MDBox pt={4} pb={3} px={3}>
				<ImagePicker onImageSelected={handleImageSelected} />

				<MDBox
					mb={2}
					pt={2}
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="80%"
					overflowX="scroll"
				>
					{state?.Image?.map((a) => (
						<img
							src={a?.data}
							alt="Selected"
							className="selected-image"
							style={{
								width: "50px",
								height: "50px",
								margin: "0px 10px",
								borderRadius: "25%"
							}}
						/>
					))}
				</MDBox>
				<MDBox
					mb={2}
					pt={2}
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="100%"
					overflow="hidden"
				>
					{state?.preview?.length > 0 && (
						<MDBox
							mb={2}
							pt={2}
							pl={50}
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="80%"
							overflow="scroll"
						>
							{state?.preview?.map((a) => (
								<img
									src={`${ImageCloud}/${a?.filename}`}
									alt="Selected"
									className="selected-image"
									style={{
										width: id !== "New" ? "100px" : "50px",
										height: id !== "New" ? "100px" : "50px",
										margin: "0px 10px",
										borderRadius: "25%"
									}}
								/>
							))}
						</MDBox>
					)}
				</MDBox>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<MDInput
							value={state?.description}
							label="Description"
							variant="standard"
							fullWidth
							onChange={(e) => handleChange("description", e.target.value)}
						/>
					</MDBox>
					<MDBox pt={2} display="flex" justifyContent="center" alignItems="center" width="100%">
						<MDBox pr={2} width="100%">
							<MDInput
								value={state?.FlatNo}
								label="FlatNo"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("FlatNo", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.Floor}
								label="Floor"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("Floor", e.target.value)}
							/>
						</MDBox>
					</MDBox>
					<MDBox pt={2} display="flex" justifyContent="center" alignItems="center" width="100%">
						<MDBox pr={2} width="100%">
							<MDInput
								value={state?.Tower}
								label="Tower"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("Tower", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<InputLabel variant="standard" htmlFor="uncontrolled-native">
								Category
							</InputLabel>
							<Select
								value={state?.Category}
								label="Category"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("Category", e.target.value)}
								style={{ textTransform: "uppercase" }}
							>
								<MenuItem value={"3BED"}>3BED</MenuItem>
								<MenuItem value={"4BED"}>4BED</MenuItem>
								<MenuItem value={"PENTHOUSE"}>PENTHOUSE</MenuItem>
							</Select>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			{id !== "New" && (
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<Grid container style={{ width: "100%" }}>
									<Grid item xs={6}>
										<MDTypography variant="h6" color="white">
											User
										</MDTypography>
									</Grid>
								</Grid>
							</MDBox>

							<MDBox pt={3}>
								{data?.User.length > 0 ? (
									<DataTable table={{ columns, rows }} isSorted showTotalEntries noEndBorder />
								) : (
									<MDBox lineHeight={1} textAlign="center">
										<MDTypography
											display="block"
											variant="caption"
											color="text"
											fontWeight="medium"
											fontSize="20px"
										>
											{error ? error : " No Data Found"}
										</MDTypography>
									</MDBox>
								)}
							</MDBox>
						</Grid>
					</Grid>
				</MDBox>
			)}
		</Card>
	);
}

export default withAuthContext(withPropertyContext(Details));
