/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, Navigate, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "../components/CoverLayout";

// Images
import bgImage from "assets/images/Bg.jpeg";
import { useState } from "react";
import axios from "axios";
import { withAuthContext } from "context/Auth";
import swal from "sweetalert";

function Cover({ setToken }) {
	const navigate = useNavigate();
	const [Loading, setLoading] = useState(false);

	const [state, setState] = useState({
		email: "",
		password: ""
	});

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};

	const handleSubmit = () => {
		if (state?.email && state?.password) {
			setLoading(true);

			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Login-Admin`, state)
				.then((res) => {
					setLoading(false);
					if (res?.data?.status == 200) {
						localStorage.setItem("token", res?.data?.token);
						setToken(res?.data?.token);
						navigate("/");
						window.location.reload();
					}
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		}
	};
	return (
		<CoverLayout image={bgImage} coverHeight="100vh">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Login to your Account
					</MDTypography>
					<MDTypography display="block" variant="button" color="white" my={1}>
						Enter your email and password to proceed
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								type="email"
								label="Email"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("email", e.target.value)}
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								type="password"
								label="Password"
								variant="standard"
								fullWidth
								onChange={(e) => handleChange("password", e.target.value)}
							/>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton
								variant="gradient"
								color="info"
								fullWidth
								onClick={() => {
									if (!Loading) {
										handleSubmit();
									} else {
										swal({
											text: "Please Let This Task Complete First",
											button: {
												text: "Ok",
												closeModal: true
											},
											icon: "warning",
											time: 3000
										});
									}
								}}
							>
								sign in
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default withAuthContext(Cover);
