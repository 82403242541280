/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "Pages/ServiceOffered/Forms/components/Fields/Field";
import { withServiceOfferedContext } from "context/ServiceOffered";
import AddFieldForm from "./AddField";
import Loader from "components/Loader";

function Fields({ stateServiceOffered, AddField, setAddField, LoadingServiceOffered }) {
	if (LoadingServiceOffered) {
		return <Loader />;
	} else {
		return (
			<Card sx={{ height: "100%" }}>
				{AddField ? (
					<AddFieldForm />
				) : (
					<>
						<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
							<MDTypography variant="h6" fontWeight="medium">
								Fields
							</MDTypography>
							<MDButton
								variant="outlined"
								color="info"
								size="small"
								onClick={() => setAddField(!AddField)}
							>
								Add Field
							</MDButton>
						</MDBox>
						<MDBox p={2}>
							<MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
								{stateServiceOffered?.Fields?.map((a) => (
									<Invoice
										key={a?._id}
										date={a?.name || ""}
										id={a?.type || ""}
										price={a?.pricePerUnit || 0}
										forUser={a?.forUser}
									/>
								))}{" "}
							</MDBox>
						</MDBox>
					</>
				)}
			</Card>
		);
	}
}

export default withServiceOfferedContext(Fields);
