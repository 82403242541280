/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Invoice from "../Invoice";
import { useNavigate } from "react-router-dom";

function Utility({ Arr }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Utility
        </MDTypography>
        <MDButton
          variant="outlined"
          color="info"
          size="small"
          onClick={() => navigate("/Utility")}
        >
          view all
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {Arr && Arr?.length > 0 ? (
            Arr?.map((a) => (
              <Invoice date={a?.title} id={a?.description} price={a?.Total} />
            ))
          ) : (
            <MDBox lineHeight={1} textAlign="center">
              <MDTypography
                display="block"
                variant="caption"
                color="text"
                fontWeight="medium"
                fontSize="20px"
              >
                No Data Found
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Utility;
