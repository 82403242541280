/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceContext } from "context/Service";
import { useEffect, useState } from "react";
import Loader from "components/Loader";

function ViewBill({ DataGetService, AdditionalCharges, Discount, LoadingService }) {
	const [Fields, setFields] = useState([]);

	const [FieldValue, setFieldValue] = useState({});

	useEffect(() => {
		if (DataGetService?.Bill?.FieldsOfServiceOffered) {
			setFields(DataGetService?.Bill?.FieldsOfServiceOffered);
		} else {
			setFields(DataGetService?.ServiceOffered?.Fields);
		}
	}, [DataGetService]);

	useEffect(() => {
		if (Fields?.length) {
			const fieldValues = {};
			for (const key of Fields) {
				const Value = DataGetService?.FieldValues?.[key?.name]
					? DataGetService?.FieldValues?.[key?.name]
					: key?.type == "number"
					? 0
					: "";
				fieldValues[key?.name] = Value; // You can set any initial value you want here
			}
			setFieldValue(fieldValues);
		}
	}, [Fields]);
	if (LoadingService) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h3" fontWeight="medium">
						Bill
					</MDTypography>
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h5" fontWeight="medium">
							Charges
						</MDTypography>
					</MDBox>
					{Fields?.map((a) => {
						if (a?.pricePerUnit > 0) {
							return (
								<MDBox
									pt={2}
									px={2}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<MDTypography variant="h6" fontWeight="light">
										{a?.name}
									</MDTypography>
									<MDTypography variant="h6" fontWeight="light">
										x {a?.pricePerUnit} = Rs{" "}
										{parseInt(parseInt(a?.pricePerUnit) * parseInt(FieldValue?.[a?.name])) || 0}
									</MDTypography>
								</MDBox>
							);
						}
					})}
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h5" fontWeight="medium">
							Additional Charges
						</MDTypography>
					</MDBox>
					{Object.keys(AdditionalCharges)?.map((a) => {
						return (
							<MDBox
								pt={2}
								px={2}
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" fontWeight="light">
									{a}
								</MDTypography>
								<MDTypography variant="h6" fontWeight="light">
									{AdditionalCharges?.[a]}
								</MDTypography>
							</MDBox>
						);
					})}
				</MDBox>
				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							Discount
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{Discount?.Discount}
						</MDTypography>
					</MDBox>
					{Discount?.Discount > 0 && (
						<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
							<MDTypography variant="h6" fontWeight="light">
								Reason For Discount
							</MDTypography>
							<MDTypography variant="h6" fontWeight="light">
								{Discount?.reasonForDiscount}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>

				<MDBox pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							SubTotal
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{DataGetService?.Bill?.Total}
						</MDTypography>
					</MDBox>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="light">
							Total
						</MDTypography>
						<MDTypography variant="h6" fontWeight="light">
							{DataGetService?.Bill?.TotalAfterDiscount}
						</MDTypography>
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withServiceContext(ViewBill);
