/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { withAuthContext } from "context/Auth";
import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceContext } from "context/Service";
import { CheckBox } from "@mui/icons-material";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Loader from "components/Loader";

function AddField({ handleChangeService, DataGetService, LoadingService }) {
	const [Fields, setFields] = useState([]);

	const [State, setState] = useState({});

	const handleChangeServiceFields = (name, value) => {
		setState({ ...State, [name]: value });
	};
	useEffect(() => {
		if (DataGetService?.Bill?.FieldsOfServiceOffered) {
			setFields(DataGetService?.Bill?.FieldsOfServiceOffered);
		} else {
			setFields(DataGetService?.ServiceOffered?.Fields);
		}
	}, [DataGetService]);

	useEffect(() => {
		if (Fields?.length) {
			const fieldValues = {};
			for (const key of Fields) {
				const Value = DataGetService?.FieldValues?.[key?.name]
					? DataGetService?.FieldValues?.[key?.name]
					: key?.type == "number"
					? 0
					: "";
				fieldValues[key?.name] = Value; // You can set any initial value you want here
			}
			setState(fieldValues);
		}
	}, [Fields]);

	useEffect(() => {
		handleChangeService("FieldValues", State);
	}, [State]);
	if (LoadingService) {
		return <Loader />;
	}else{
    return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDBox
						pt={2}
						px={2}
						display="flex"
						flexDirection="column"
						justifyContent="flex-start"
						alignItems="flex-start"
					>
						<MDTypography variant="h6" fontWeight="medium">
							Fields of Service
						</MDTypography>
						{DataGetService?.Bill?.FieldsOfServiceOffered && (
							<MDTypography variant="h6" color="error" fontWeight="medium">
								Fields Can Not be Updated as the Bill is Already Generted for The Service
							</MDTypography>
						)}
					</MDBox>
				</MDBox>

				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						{Fields?.map((a) => (
							<MDBox mb={2}>
								<MDInput
									value={State?.[a?.name]}
									label={a?.name}
									type={a?.type}
									disabled={DataGetService?.Bill?.FieldsOfServiceOffered.length > 0 || a?.forUser}
									variant="standard"
									fullWidth
									onChange={(e) => handleChangeServiceFields(a?.name, e.target.value)}
								/>
								{a?.pricePerUnit > 0 && (
									<MDTypography variant="h6" color="error" fontWeight="medium">
										x {a?.pricePerUnit} = Rs{" "}
										{parseInt(parseInt(a?.pricePerUnit) * parseInt(State?.[a?.name])) || 0}
									</MDTypography>
								)}
							</MDBox>
						))}
					</MDBox>
				</MDBox>
			</Card>
		);
  }
}

export default withServiceContext(AddField);
