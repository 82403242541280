/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// ServiceActionFrom page components
import DetailsForm from "Pages/Service/Forms/components/Details";
import Fields from "Pages/Service/Forms/components/Fields";
import Services from "Pages/Property/Forms/components/Services";
import MasterCard from "examples/Cards/MasterCard";
import { withServiceContext } from "context/Service";
import Property from "Pages/Property";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Bill from "./components/Bill";
import ViewBill from "./components/Bill/ViewBill";
import Payment from "./components/Payment";
import Review from "./components/Review";
import Task from "../../Task"
import Comment from "Pages/Comment";
function ServiceActionFrom({
  stateService,
  DataGetService,
  SetPropertyIdService,
  setServiceOfferedId,
  setServiceId,
  handleChangeService,
  GetService,
  GetServiceOffered,
  setStateService,
  setDataGetService,
}) {
  const { id, serviceOfferedId: SOID } = useParams();

  useEffect(() => {
    if (id == "New") {
      setStateService({
        id: "",
        description: "",
        reason: "",
        status: "active",
        FieldValues: {},
      });
      setDataGetService({
        id: "",
        ServiceOffered: {},
        Property: {},
        description: "",
        reason: "",
        status: "active",
        FieldValues: {},
      });
    }
  }, []);

  useEffect(() => {
    handleChangeService("id", id);
    setServiceId(id);
    if (id !== "New") {
      GetService(id);
    }
  }, [id]);

  useEffect(() => {
    handleChangeService("ServiceOffered", SOID);
    setServiceOfferedId(SOID);

    if (id == "New") {
      GetServiceOffered(SOID);
    }
  }, [SOID]);

  return (
    <DashboardLayout>
      <>
        <DashboardNavbar absolute isMini />
        <MDBox mt={8}>
          <MDBox mb={3}>
            {DataGetService?.Property?._id &&
              DataGetService?.Property?._id !== "" &&
              stateService?.Property ? (
              <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} lg={8}>
                  {id !== "New" && (
                    <Grid item xs={12} lg={12}>
                      <Task ServiceID={id} ComplainID={""} />
                    </Grid>
                  )}
                  <Grid item xs={12} xl={6}>
                    <MasterCard
                      number={
                        DataGetService?.Property?.description || "Property Name"
                      }
                      holder={DataGetService?.Property?.noRooms || "1"}
                      expires={DataGetService?.Property?.noBathrooms || "1"}
                      background={"assets/images/illustrations/pattern-tree.svg"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <DetailsForm />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Fields />
                  </Grid>

                  {id !== "New" && !DataGetService?.Payment?._id && (
                    <Grid item xs={12} lg={6}>
                      <Bill />
                    </Grid>
                  )}
                  {DataGetService?.Bill?._id && (
                    <>
                      <Grid item xs={12} lg={6}>
                        <ViewBill />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Payment />
                      </Grid>
                    </>
                  )}
                  {DataGetService?.Review?._id && (
                    <Grid item xs={12} lg={6}>
                      <Review />
                    </Grid>
                  )}
                </Grid>
                {id !== "New" && (
                  <Grid item xs={12} lg={3}>
                    <Comment ServiceID={id} ComplainID={""} />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Property
                serviceId={true}
                SetPropertyIdService={SetPropertyIdService}
              />
            )}
          </MDBox>
        </MDBox>
      </>
    </DashboardLayout>
  );
}

export default withServiceContext(ServiceActionFrom);
