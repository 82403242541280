// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

function Tables({ Token, CheckToken, AdminRole }) {
	const columns = [
		{
			headerName: "Action",
			field: "action",
			width: 200,
			renderCell: ({ row: a }) => (!a?.ExitTime || a?.ExitTime == "") && (
				<MDBox>
					<MDBadge
						badgeContent={"Visitor Left"}
						color={"dark"}
						variant="gradient"
						size="sm"
						onClick={() => {
							handleChangeTime({ id: a?._id });
						}}
					/>
				</MDBox>
			)
		},
		{ headerName: "Name", field: "Name" },
		{ headerName: "TowerNo", field: "TowerNo" },
		{ headerName: "AppratmentNo", field: "AppratmentNo" },
		{ headerName: "VehicleNumber", field: "VehicleNumber" },
		{ headerName: "VisitPurpose", field: "VisitPurpose" },
		{
			headerName: "EntryTime", field: "EntryTime", width: 200, renderCell: ({ row: a }) => (
				<MDBox>
					{moment(a?.EntryTime).format("hh:mm DD/MM/YYYY")}
				</MDBox>
			)
		},
		{
			headerName: "ExitTime", field: "ExitTime", width: 200, renderCell: ({ row: a }) => (
				<MDBox>
					{moment(a?.ExitTime).format("hh:mm DD/MM/YYYY")}
				</MDBox>
			)
		},
		{ headerName: "Visiting", field: "Visiting" },
		{ headerName: "ContactNumber", field: "ContactNumber" },
		{
			headerName: "Notes",
			field: "Notes"
		},

	];
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const GetVisitors = () => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllVisitors`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					if (res?.data?.status == 200) {
						setData(res?.data?.data.reverse());
					} else {
						setError(res?.data?.message);
					}
				})
				.catch((err) => {
					setError(err?.message);
				});
		} else {
			setTimeout(() => {
				CheckToken();
				GetVisitors();
			}, 500);
		}
	};

	const handleChangeTime = ({ id }) => {
		if (Token) {
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Change-ExitTime-Visitor`, {
					id: id,
					ExitTime: new Date()
				}, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					GetVisitors();
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setTimeout(() => {
				CheckToken();
				handleChangeTime();
			}, 500);
		}
	};

	useEffect(() => {
		GetVisitors();
	}, []);

	useEffect(() => {
		GetVisitors();
	}, [AdminRole]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<Grid container style={{ width: "100%" }}>
									<Grid item xs={6}>
										<MDTypography variant="h6" color="white">
											Visitor
										</MDTypography>
									</Grid>
									<Grid
										item
										xs={6}
										style={{
											display: "flex",
											alignItems: "flex-end",
											alignContent: "flex-end",
											justifyContent: "flex-end"
										}}
									>
										<MDBadge
											badgeContent="New"
											color="dark"
											variant="gradient"
											size="sm"
											onClick={() => navigate(`/VisitorAction/New`)}
										/>
									</Grid>
								</Grid>
							</MDBox>

							<MDBox pt={3}>
								{data.length > 0 ? (
									<DataTable rows={data} columns={columns} />
								) : (
									<MDBox lineHeight={1} textAlign="center">
										<MDTypography
											display="block"
											variant="caption"
											color="text"
											fontWeight="medium"
											fontSize="20px"
										>
											{error ? error : " No Data Found"}
										</MDTypography>
									</MDBox>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default withAuthContext((Tables));
