/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import {
	Card,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

function Details({ state }) {

	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Additional Details
				</MDTypography>
			</MDBox>
			<MDBox pt={4} pb={3} px={3}>
				<div style={{
					 display: "grid",
  gridTemplateColumns:" 1fr 1fr 1fr",
	gridGap: "10px"
				}} >
							<MDInput
								value={state?.Qualification}
								label="Qualification"
								variant="standard"
								fullWidth
								disabled={true}
								/>
										<MDInput
								value={state?.Designation}
								label="Designation"
								variant="standard"
								fullWidth
								disabled={true}
								/>
									<MDInput
								value={state?.Proffesion}
								label="Proffesion"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.NameOfOrganization}
								label="NameOfOrganization"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.AddressOfOrganization}
								label="AddressOfOrganization"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.PresentAddress}
								label="PresentAddress"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.PermanentAddress}
								label="PermanentAddress"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.TelePhoneOffice}
								label="TelePhoneOffice"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.Residence}
								label="Residence"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.Fax}
								label="Fax"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.Other}
								label="Other"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.NoOfFamilyMembers}
								label="NoOfFamilyMembers"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.Adults}
								label="Adults"
								variant="standard"
								fullWidth
								disabled={true}
								/>	<MDInput
								value={state?.Children}
								label="Children"
								variant="standard"
								fullWidth
								disabled={true}
								/>
									</div>
			</MDBox>
		</Card>
	);
}

export default ((Details));
