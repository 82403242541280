// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import CarIcon from "assets/images/bg-profile.jpeg";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { withPropertyContext } from "context/Property";

function Tables({ Token, CheckToken, AdminRole }) {
	const columns = [
		{
			headerName: "User",
			field: "User",
			valueGetter: (params) => `${params.row?.User?.name || "-"}`
		},
		{
			headerName: "Property",
			field: "Property",
			valueGetter: (params) => `${params.row?.Property?.FlatNo || "-"}`
		},
		{
			headerName: "Tower",
			field: "Tower",
			valueGetter: (params) => `${params.row?.Property?.Tower || "-"}`
		},
		{ headerName: "Number", field: "Number" },
		{ headerName: "Color", field: "Color" },
		{ headerName: "SlotNo", field: "SlotNo" },
		{ headerName: "LicenseNo", field: "LicenseNo" },
		{ headerName: "Name", field: "Name" },
		{
			headerName: "Description",
			field: "Description"
		}
	];
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const GetCars = () => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllCars`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					if (res?.data?.status == 200) {
						setData(res?.data?.data.reverse());
					} else {
						setError(res?.data?.message);
					}
				})
				.catch((err) => {
					setError(err?.message);
				});
		} else {
			setTimeout(() => {
				CheckToken();
				GetCars();
			}, 500);
		}
	};

	useEffect(() => {
		GetCars();
	}, []);

	useEffect(() => {
		GetCars();
	}, [AdminRole]);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
							>
								<Grid container style={{ width: "100%" }}>
									<Grid item xs={6}>
										<MDTypography variant="h6" color="white">
											Car
										</MDTypography>
									</Grid>
									{(AdminRole == "admin" ||
										AdminRole == "superadmin" ||
										AdminRole == "manager") && (
										<Grid
											item
											xs={6}
											style={{
												display: "flex",
												alignItems: "flex-end",
												alignContent: "flex-end",
												justifyContent: "flex-end"
											}}
										>
											<MDBadge
												badgeContent="New"
												color="dark"
												variant="gradient"
												size="sm"
												onClick={() => navigate(`/CarProperty/New`)}
											/>
										</Grid>
									)}
								</Grid>
							</MDBox>

							<MDBox pt={3}>
								{data.length > 0 ? (
									<DataTable rows={data} columns={columns} />
								) : (
									<MDBox lineHeight={1} textAlign="center">
										<MDTypography
											display="block"
											variant="caption"
											color="text"
											fontWeight="medium"
											fontSize="20px"
										>
											{error ? error : " No Data Found"}
										</MDTypography>
									</MDBox>
								)}
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default withAuthContext(withPropertyContext(Tables));
