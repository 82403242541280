// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import UserIcon from "assets/images/bg-profile.jpeg";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Icon, Tab, Tabs } from "@mui/material";
import { withPropertyContext } from "context/Property";
import breakpoints from "assets/theme/base/breakpoints";
import { EmojiTransportation, House, SupervisedUserCircleOutlined } from "@mui/icons-material";
import ImageCloud from "../../link";

function Tables({
	Token,
	RemovePropertyToUser,
	CheckToken,
	AdminRole,
	setUser,
	PropertyIdComplain
}) {
	const { propertyId } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const [tabsOrientation, setTabsOrientation] = useState("horizontal");
	const [tabValue, setTabValue] = useState("All");

	const columns = [
		{
			headerName: "",
			field: "profilePicture",
			flex: 1,
			renderCell: ({ row: a }) => (
				<img
					src={
						a?.profilePicture?.filename
							? `${ImageCloud}/${a?.profilePicture?.filename}`
							: UserIcon
					}
					style={{
						width: "50px",
						height: "50px",
						borderRadius: "50px",
						border: "1px solid #000"
					}}
				/>
			)
		},
		{ headerName: "Name", field: "name", flex: 1 },
		{ headerName: "Email", field: "email", flex: 1 },
		{ headerName: "Type", field: "Type", flex: 1 },
		{ headerName: "Phone Number", field: "phoneNumber", flex: 1 },
		{
			headerName: "Flat No",
			field: "flatNo",
			flex: 1,
			valueGetter: ({ row: a }) => `${a?.Property?.FlatNo ? a?.Property?.FlatNo : a?.flatNo}
    `
		},
		{ headerName: "Otp", field: "otp", flex: 1 },
		{ headerName: "Notifications", field: "notifications", flex: 1 },
		{
			headerName: "Action",
			field: "action",
			flex: 2,
			renderCell: ({ row: a }) => (
				<MDBox>
					{propertyId ? (
						<MDBadge
							badgeContent="Select User"
							color={"dark"}
							variant="gradient"
							size="sm"
							onClick={() => navigate(`/CarAction/${propertyId}/${a?._id}/New`)}
						/>
					) : setUser ? (
						<MDBadge
							badgeContent={"Select User"}
							color={"warning"}
							variant="gradient"
							size="sm"
							onClick={() => {
								setUser(a?._id);
							}}
						/>
					) : (
						<>
							{(AdminRole == "admin" || AdminRole == "superadmin" || AdminRole == "manager") && (
								<MDBadge
									badgeContent="View User"
									color={"dark"}
									variant="gradient"
									size="sm"
									onClick={() => navigate(`/UserAction/${a?.Property?._id}/${a?._id}`)}
								/>
							)}

							{!a?.Property?._id && (AdminRole == "admin" || AdminRole == "superadmin") ? (
								<MDBadge
									badgeContent="Add Property"
									color={"warning"}
									variant="gradient"
									size="sm"
									onClick={() => navigate(`/SelectProperty/${a?._id}`)}
								/>
							) : (
								(AdminRole == "admin" || AdminRole == "superadmin") && (
									<MDBadge
										badgeContent="Remove Property"
										color={"warning"}
										variant="gradient"
										size="sm"
										onClick={() =>
											RemovePropertyToUser({
												userId: a?._id,
												propertyId: a?.Property?._id,
												GetUsers
											})
										}
									/>
								)
							)}
						</>
					)}
				</MDBox>
			)
		}
	];

	useEffect(() => {
		// A function that sets the orientation state of the tabs.
		function handleTabsOrientation() {
			return window.innerWidth < breakpoints.values.sm
				? setTabsOrientation("vertical")
				: setTabsOrientation("horizontal");
		}

		/** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
		window.addEventListener("resize", handleTabsOrientation);

		// Call the handleTabsOrientation function to set the state with the initial value.
		handleTabsOrientation();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleTabsOrientation);
	}, [tabsOrientation]);

	const handleSetTabValue = (event, newValue) => {
		setTabValue(newValue);
	};

	const GetUsers = () => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllUsers/${tabValue}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					if (res?.data?.status == 200) {
						let arr = res?.data?.data;
						if (PropertyIdComplain || propertyId) {
							arr = arr.filter((a) => {
								if (a?.Property?._id == (PropertyIdComplain || propertyId)) {
									return a;
								}
							});
						}
						setData(arr);
					} else {
						setError(res?.data?.message);
					}
				})
				.catch((err) => {
					setError(err?.message);
				});
		} else {
			setTimeout(() => {
				CheckToken();
				GetUsers();
			}, 500);
		}
	};

	useEffect(() => {
		GetUsers();
	}, []);

	useEffect(() => {
		GetUsers();
	}, [tabValue, AdminRole]);

	function Table() {
		return (
			<Card>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid container style={{ width: "100%" }}>
						<Grid item xs={6}>
							<MDTypography variant="h6" color="white">
								{tabValue == "rentant"
									? "Property Rentant Details"
									: tabValue == "owner"
									? "Property Owner Details"
									: "All Users Details"}
							</MDTypography>
						</Grid>
						{(AdminRole == "admin" || AdminRole == "superadmin" || AdminRole == "manager") &&
							!setUser && (
								<Grid
									item
									xs={6}
									style={{
										display: "flex",
										alignItems: "flex-end",
										alignContent: "flex-end",
										justifyContent: "flex-end"
									}}
								>
									<MDBadge
										badgeContent="New"
										color="dark"
										variant="gradient"
										size="sm"
										onClick={() => navigate(`/UserProperty/New`)}
									/>
								</Grid>
							)}
					</Grid>
				</MDBox>

				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid
						container
						style={{
							width: "100%",
							alignItems: "flex-end",
							alignContent: "flex-end",
							justifyContent: "flex-end"
						}}
					>
						<Grid item md={6} xs={12}>
							<Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
								<Tab
									label="All"
									value={"All"}
									icon={<SupervisedUserCircleOutlined fontSize="small" sx={{ mt: -0.25 }} />}
								/>
								<Tab
									label="Owners"
									value={"owner"}
									icon={<EmojiTransportation fontSize="small" sx={{ mt: -0.25 }} />}
								/>
								<Tab
									label="Rentants"
									value={"rentant"}
									icon={<House fontSize="small" sx={{ mt: -0.25 }} />}
								/>
							</Tabs>
						</Grid>
					</Grid>
				</MDBox>

				<MDBox pt={3}>
					{data.length > 0 ? (
						<DataTable rows={data} columns={columns} />
					) : (
						<MDBox lineHeight={1} textAlign="center">
							<MDTypography
								display="block"
								variant="caption"
								color="text"
								fontWeight="medium"
								fontSize="20px"
							>
								{error ? error : " No Data Found"}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</Card>
		);
	}

	if (setUser) {
		return <Table />;
	} else {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Table />
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
		);
	}
}

export default withAuthContext(withPropertyContext(Tables));
