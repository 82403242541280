/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withServiceContext } from "context/Service";
import { useState } from "react";
import swal from "sweetalert";
import Loader from "components/Loader";

function Review({ DataGetService, LoadingService }) {
	function floatToStars(rating) {
		if (rating >= 0 && rating <= 5) {
			const numStars = Math.round(rating);
			const fullStars = "★".repeat(numStars);
			const emptyStars = "☆".repeat(5 - numStars);
			return fullStars + emptyStars;
		} else {
			return "Invalid rating. Rating must be between 0 and 5.";
		}
	}

	const stars = floatToStars(DataGetService?.Review?.Rating);
	if (LoadingService) {
		return <Loader />;
	} else {
		return (
			<Card id="delete-account">
				<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
					<MDTypography variant="h6" fontWeight="medium">
						Review
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="medium">
							Ratings
						</MDTypography>
						<MDTypography variant="h6" fontWeight="medium">
							{stars}
						</MDTypography>
					</MDBox>
					<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
						<MDTypography variant="h6" fontWeight="medium">
							Comments
						</MDTypography>
						<MDTypography variant="h6" fontWeight="medium">
							{DataGetService?.Review?.description}
						</MDTypography>
					</MDBox>
				</MDBox>
			</Card>
		);
	}
}

export default withServiceContext(Review);
