import React, { createContext, useEffect } from "react";
import { withAuthContext } from "./Auth";
import axios from "axios";
import swal from "sweetalert";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ServiceContext = createContext();

export const withServiceContext = (Component) => (props) =>
	(
		<ServiceContext.Consumer>
			{(value) => <Component {...value} {...props} />}
		</ServiceContext.Consumer>
	);

const ServiceProvider = ({ children, Token, CheckToken }) => {
	const [LoadingService, setLoadingService] = useState(false);

	const [serviceId, setServiceId] = useState("");
	const [serviceOfferedId, setServiceOfferedId] = useState("");
	const [DataGetService, setDataGetService] = useState({
		id: "",
		ServiceOffered: {},
		Property: {},
		description: "",
		reason: "",
		status: "active",
		FieldValues: {}
	});

	const [stateService, setStateService] = useState({
		id: "",
		ServiceOffered: "",
		Property: "",
		description: "",
		reason: "",
		status: "active",
		FieldValues: {}
	});

	const [AdditionalCharges, setAdditionalCharges] = useState({});
	const [Discount, setDiscount] = useState({
		Discount: 0,
		reasonForDiscount: ""
	});

	const handleAddCharges = (name, value) => {
		setAdditionalCharges({ ...AdditionalCharges, [name]: value });
	};

	const [statePayment, setStatePayment] = useState({
		id: "",
		Amount: 0,
		Type: "cash",
		description: "",
		Property: "",
		Service: "",
		Bill: ""
	});

	const navigate = useNavigate();

	const handleChangePayment = (name, value) => {
		setStatePayment({ ...statePayment, [name]: value });
	};

	const handleDiscount = (name, value) => {
		setDiscount({ ...Discount, [name]: value });
	};

	const handleChangeService = (name, value) => {
		setStateService({ ...stateService, [name]: value });
	};

	const GetService = (id) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingService(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/ServiceInfo/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingService(false);
					if (res?.data?.status == 200) {
						setDataGetService(res?.data?.data);
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: err?.message ? err?.message : "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();
				GetService(id);
			}, 500);
		}
	};

	const GetProperty = (PropertyId) => {
		setLoadingService(true);
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/PropertyInfo/${PropertyId}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingService(false);
					if (res?.data?.status == 200) {
						setDataGetService({
							...DataGetService,
							Property: res?.data?.data
						});
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();

				GetProperty(PropertyId);
			}, 500);
		}
	};

	const GetServiceOffered = (id) => {
		setLoadingService(true);
		if (Token || localStorage.getItem("token")) {
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/ServiceOfferedInfo/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingService(false);
					if (res?.data?.status == 200) {
						setDataGetService({
							...DataGetService,
							ServiceOffered: res?.data?.data
						});
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();
				GetServiceOffered(id);
			}, 500);
		}
	};

	const SetPropertyIdService = (PropertyId) => {
		handleChangeService("Property", PropertyId);
		GetProperty(PropertyId);
		if (stateService?.ServiceOffered) GetServiceOffered(stateService?.ServiceOffered);
	};

	const handleSubmitService = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingService(true);
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${
						serviceId !== "New" ? "Update-Service" : "Create-Service"
					}`,
					stateService,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingService(false);
					setStateService({
						id: "",
						description: "",
						reason: "",
						status: "active",
						FieldValues: {}
					});
					setDataGetService({
						id: "",
						ServiceOffered: {},
						Property: {},
						description: "",
						reason: "",
						status: "active",
						FieldValues: {}
					});
					setServiceId("");
					setServiceOfferedId("");
					navigate("/Service");
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitService();
			}, 500);
		}
	};

	const handleSubmitBill = () => {
		const Obj = {
			id: DataGetService?.Bill?._id || "",
			status: "unpaid",
			Property: stateService?.Property,
			Service: serviceId,
			AdditionalCharges: AdditionalCharges,
			Discount: Discount?.Discount,
			reasonForDiscount: Discount?.reasonForDiscount,
			Remarks: Discount?.Remarks
		};
		setLoadingService(true);

		if (Token || localStorage.getItem("token")) {
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${
						DataGetService?.Bill?._id ? "Update-Bill" : "Create-Bill"
					}`,
					Obj,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingService(false);
					GetService(serviceId);
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitBill();
			}, 500);
		}
	};

	const handleSubmitPayment = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingService(true);
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Create-Payment`, statePayment, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingService(false);
					setStatePayment({
						id: "",
						Amount: 0,
						Type: "cash",
						description: "",
						Property: "",
						Service: "",
						Bill: ""
					});
					GetService(serviceId);
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingService(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingService(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitPayment();
			}, 500);
		}
	};

	useEffect(() => {
		handleChangeService("ServiceOffered", serviceOfferedId);
		if (serviceOfferedId && serviceId == "New") {
			GetServiceOffered(serviceOfferedId);
		}
	}, [serviceOfferedId]);

	useEffect(() => {
		if (serviceId && serviceId !== "New") {
			GetService(serviceId);
		}
		handleChangeService("id", serviceId);
	}, [serviceId]);

	useEffect(() => {
		if (serviceId !== "New") {
			setStateService({
				id: serviceId,
				ServiceOffered: DataGetService?.ServiceOffered?._id,
				Property: DataGetService?.Property?._id,
				description: DataGetService?.description,
				reason: DataGetService?.reason,
				status: DataGetService?.status?.toLowerCase(),
				FieldValues: DataGetService?.FieldValues
			});
			setAdditionalCharges(DataGetService?.Bill?.AdditionalCharges || {});
			setDiscount({
				Discount: DataGetService?.Bill?.Discount || 0,
				reasonForDiscount: DataGetService?.Bill?.reasonForDiscount || ""
			});
			setStatePayment({
				id: DataGetService?.Payment?._id,
				Amount: DataGetService?.Payment?.Amount,
				Type: DataGetService?.Payment?.Type?.toLowerCase(),
				description: DataGetService?.Payment?.description,
				Property: DataGetService?.Property?._id,
				Service: serviceId,
				Bill: DataGetService?.Bill?._id
			});
		}
	}, [DataGetService]);

	return (
		<ServiceContext.Provider
			value={{
				handleChangeService,
				handleSubmitService,
				stateService,
				GetService,
				DataGetService,
				serviceId,
				setServiceId,
				serviceOfferedId,
				setServiceOfferedId,
				SetPropertyIdService,
				GetServiceOffered,
				setStateService,
				setDataGetService,
				handleSubmitBill,
				AdditionalCharges,
				handleAddCharges,
				Discount,
				handleDiscount,
				handleSubmitPayment,
				statePayment,
				handleChangePayment,
				LoadingService
			}}
		>
			{children}
		</ServiceContext.Provider>
	);
};

export default withAuthContext(ServiceProvider);
