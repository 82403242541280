/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { withComplainContext } from "context/Complain";
import { useEffect } from "react";
import VoiceNote from "../VoiceNote";
import Loader from "components/Loader";
import ImageCloud from "../../../../../link";

function Details({
	stateComplain,
	handleSubmitComplain,
	handleChangeComplain,
	ComplainId,
	DataGetComplain,
	LoadingComplain
}) {
	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				{!DataGetComplain?.Payment?._id && (
					<MDButton
						variant="gradient"
						color="dark"
						onClick={() => {
							handleSubmitComplain();
						}}
					>
						{ComplainId !== "New" ? "update" : "Add"}
					</MDButton>
				)}
			</MDBox>
			<MDBox pt={2} px={2} display="flex" justifyContent="center" alignItems="center">
				<MDTypography variant="h6" color="black">
					{DataGetComplain?.ComplainCategory?.title?.toUpperCase()}
				</MDTypography>
			</MDBox>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							Voice Note
						</InputLabel>
						<VoiceNote
							File={`${ImageCloud}/${DataGetComplain?.VoiceNote?.filename}`}
							handleChange={(e) => {
								handleChangeComplain("VoiceNote", e);
							}}
						/>
					</MDBox>

					<MDBox mb={2}>
						<InputLabel variant="standard" htmlFor="uncontrolled-native">
							status
						</InputLabel>
						<Select
							value={stateComplain?.status}
							label="status"
							variant="standard"
							fullWidth
							disabled={DataGetComplain?.Payment?._id}
							onChange={(e) => handleChangeComplain("status", e.target.value)}
							style={{ textTransform: "uppercase" }}
						>
							<MenuItem value={"active"}>active</MenuItem>
							<MenuItem value={"inactive"}>inactive</MenuItem>
							<MenuItem value={"canceled"}>canceled</MenuItem>
							<MenuItem value={"delayed"}>delayed</MenuItem>
							<MenuItem value={"canceledByUser"}>canceledByUser</MenuItem>
							{DataGetComplain?.Bill?._id && <MenuItem value={"completed"}>completed</MenuItem>}
						</Select>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							value={stateComplain?.title}
							label="title"
							variant="standard"
							fullWidth
							disabled={DataGetComplain?.Payment?._id}
							onChange={(e) => handleChangeComplain("title", e.target.value)}
						/>
					</MDBox>

					<MDBox mb={2}>
						<MDInput
							value={stateComplain?.description}
							label="description"
							variant="standard"
							fullWidth
							disabled={DataGetComplain?.Payment?._id}
							onChange={(e) => handleChangeComplain("description", e.target.value)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
}

export default withComplainContext(Details);
