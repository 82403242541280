const generateDataset = (label, data) => {
	return {
		labels: ["Jan", "Feb", "March", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
		datasets: { label, data }
	};
};

const generateFrontendData = async (data) => {
	if (data) {
		const currentYear = new Date().getFullYear();

		const Bills = generateDataset(
			"Sales",
			data?.Bill?.length &&
				data?.Bill?.reduce((monthlySum, bill) => {
					const billYear = new Date(bill.created_at).getFullYear();

					if (billYear === currentYear) {
						const month = new Date(bill.created_at).getMonth();
						const billAmount = bill.TotalAfterDiscount || 0;

						if (!monthlySum[month]) {
							monthlySum[month] = 0;
						}

						monthlySum[month] += billAmount;
					}
					return monthlySum;
				}, Array(12).fill(0))
		);
		const Payment = generateDataset(
			"Payments",
			data?.Payment?.length &&
				data?.Payment?.reduce((monthlySum, payment) => {
					const paymentYear = new Date(payment.created_at).getFullYear();

					if (paymentYear === currentYear) {
						const month = new Date(payment.created_at).getMonth();
						const paymentAmount = payment.Amount || 0;

						if (!monthlySum[month]) {
							monthlySum[month] = 0;
						}

						monthlySum[month] += paymentAmount;
					}
					return monthlySum;
				}, Array(12).fill(0))
		);
		return { Bills, Payment };
	}

	// Return some default data or handle the error case
	return null;
};

export default generateFrontendData;
