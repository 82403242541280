/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Chat from "./components/Messaging/Chat";
import ChatBox from "./components/Messaging/ChatBox/index";

function ServiceOfferedAction() {
  return (
    <DashboardLayout>
      <>
        <DashboardNavbar absolute isMini />
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container>
              <Grid item xs={12} lg={5}>
                <Chat />
              </Grid>
              <Grid item xs={12} lg={7}>
                <ChatBox />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </>
    </DashboardLayout>
  );
}

export default ServiceOfferedAction;
