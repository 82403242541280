/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { Card, Icon, InputLabel, MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Admin from "../Admin/index";
import { useEffect, useState } from "react";
import DataTable from "examples/Tables/MUI";
import moment from "moment";
import axios from "axios";
import { withAuthContext } from "context/Auth";
import AdminIcon from "assets/images/bg-profile.jpeg";
import MDBadge from "components/MDBadge";
import swal from "sweetalert";
import Loader from "components/Loader";
import ImageCloud from "../../link";
import ChatMessage from "Pages/Chat/components/Messaging/ChatBox/ChatAppComponents/ChatMessage";
import ChatInput from "Pages/Chat/components/Messaging/ChatBox/ChatAppComponents/ChatInput";


const initialState = {
	inputHasFocus: true,
	isEmojiPopoverOpen: false,
	isGifPopoverOpen: false,
	isVoiceRecording: false,
	isImageUploader: false,
};
function Comment({ ComplainID, ServiceID, Token, CheckToken, AdminRole }) {
	const [AllComment, setAllComment] = useState([]);
	const [errorAllComment, setErrorAllComment] = useState("");

	const [Loading, setLoading] = useState(false);
	const [text, setText] = useState(null);
	const [state, setState] = useState({ ...initialState });


	const GetComments = () => {
		if (Token || localStorage.getItem("token")) {
			setLoading(true);
			axios
				.get(
					`${process.env.REACT_APP_PUBLIC_PATH}/${ServiceID ? `CommentInfoService/${ServiceID}` : `CommentInfoComplain/${ComplainID}`
					}`,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoading(false);

					if (res?.data?.status == 200) {
						setAllComment(res?.data?.data);
					} else {
						setErrorAllComment(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoading(false);
					setErrorAllComment(err?.message);
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				GetComments();
			}, 500);
		}
	};

	useEffect(() => {
		GetComments();
	}, []);

	useEffect(() => {
		GetComments();
	}, [ServiceID, ComplainID]);
	const AddComment = async () => {
		if (Token || localStorage.getItem("token")) {
			setLoading(true);
			const Obj = {
				serviceId: null,
				complainId: null,
				text: text.trim()
			};
			if (ServiceID) {
				Obj.serviceId = ServiceID;
			} else if (ComplainID) {
				Obj.complainId = ComplainID;
			}
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/CreateComment`, Obj, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoading(false);
					if (res?.data?.status == 200) {
						swal({
							text: "Comment was Added to Admin",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: res?.data?.status == 200 ? "success" : "error",
							time: 3000
						});
						GetComments();
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				AddComment();
				GetComments();
			}, 500);
		}
	};
	return (
		<Card id="delete-account" style={{
			height: "100vh"
		}}>
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Comments
				</MDTypography>
			</MDBox>
			<MDBox pt={3} style={{
				height: "100vh"
			}}>
				<MDBox pt={3} style={{
					height: "90vh",
					display: "flex",
					flexDirection: "column",
					alignItems: "start",
					justifyContent: "flex-end"
				}}>
					{Loading ? (
						<Loader />
					) : AllComment.length > 0 ? (
						AllComment?.map((a, index) => (
							<>
								<p style={{
									fontSize: "14px",
									width: "80%",
									textAlign: "justify",
									padding: "10px",
									color: "#FFF",
									background: "#78120F",
									borderRadius: "25px",
									margin: "10px"
								}}>
									<p style={{
										fontSize: "10px",
										width: "80%",
										textAlign: "justify",
										padding: "10px",
										color: "#FFF",
										margin: "5px",
										fontWeight: "900"
									}}>{a?.admin?.name}</p>

									{a?.text}
								</p>
							</>))
					) : (
						<MDBox lineHeight={1} textAlign="center">
							<MDTypography
								display="block"
								variant="caption"
								color="text"
								fontWeight="medium"
								fontSize="20px"
							>
								{errorAllComment ? errorAllComment : "There are Still No  Comments"}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>

				<ChatInput
					onEmoji={() => { }}
					onGif={() => { }}
					onTextInput={setText}
					onDrop={() => { }}
					LoadingChat={Loading}
					onVoiceNote={(e) => { }}
					message={text}
					onSend={async () => {
						if (text.trim() != "" && text.trim() != "\n") {
							await AddComment();
							setText("");
						}
					}}
					onResetText={() => setText("")}
					state={state}
					setState={setState}
				/>

			</MDBox>
		</Card>
	);
}

export default withAuthContext(Comment);
