import React, { createContext, useEffect } from "react";
import { withAuthContext } from "./Auth";
import axios from "axios";
import swal from "sweetalert";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ComplainContext = createContext();

export const withComplainContext = (Component) => (props) =>
(
	<ComplainContext.Consumer>
		{(value) => <Component {...value} {...props} />}
	</ComplainContext.Consumer>
);

const ComplainProvider = ({ children, Token, CheckToken }) => {
	const navigate = useNavigate();

	const [ComplainId, setComplainId] = useState("");
	const [ComplainCategoryId, setComplainCategoryId] = useState("");
	const [PropertyComplainId, setPropertyComplainId] = useState("");
	const [UserComplainId, setUserComplainId] = useState("");

	const [DataProperty, setDataProperty] = useState({});
	const [DataComplainCategory, setDataComplainCategory] = useState({});

	const [DataGetComplain, setDataGetComplain] = useState({
		status: "active",
		title: "",
		description: "",
		VoiceNote: {},
		ComplainCategory: {},
		Property: {},
		User: {},
		Chat: {}
	});

	const [stateComplain, setStateComplain] = useState({
		status: "active",
		title: "",
		description: "",
		VoiceNote: {},
		User: ""
	});

	// Bill And Payment States
	const [Field, setField] = useState({});

	const [AdditionalCharges, setAdditionalCharges] = useState({});
	const [Discount, setDiscount] = useState({
		Discount: 0,
		reasonForDiscount: "",
		Remarks: ""
	});

	const [statePayment, setStatePayment] = useState({
		id: "",
		Amount: 0,
		Type: "cash",
		description: "",
		Property: "",
		Complain: "",
		Bill: ""
	});

	const [LoadingComplain, setLoadingComplain] = useState(false);

	const handleAddCharges = (name, value) => {
		setAdditionalCharges({ ...AdditionalCharges, [name]: value });
	};

	const handleFields = (name, value) => {
		setField({ ...Field, [name]: value });
	};

	const handleChangePayment = (name, value) => {
		setStatePayment({ ...statePayment, [name]: value });
	};

	const handleDiscount = (name, value) => {
		setDiscount({ ...Discount, [name]: value });
	};

	const handleChangeComplain = (name, value) => {
		setStateComplain({ ...stateComplain, [name]: value });
	};

	const GetComplain = (id) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingComplain(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/ComplainInfo/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingComplain(false);
					if (res?.data?.status == 200) {
						setDataGetComplain(res?.data?.data);
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingComplain(false);

					swal({
						text: err?.message ? err?.message : "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();
				GetComplain(id);
			}, 500);
		}
	};

	const GetProperty = (PropertyId) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingComplain(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/PropertyInfo/${PropertyId}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingComplain(false);

					if (res?.data?.status == 200) {
						const Obj = DataGetComplain;
						Obj.Property = res?.data?.data;
						Obj.ComplainCategory = DataComplainCategory;

						setDataProperty(res?.data?.data);
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingComplain(false);
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();
				GetProperty(PropertyId);
			}, 500);
		}
	};

	const GetComplainCategory = (id) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingComplain(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/ComplainCategoryInfo/${id}`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingComplain(false);
					if (res?.data?.status == 200) {
						const Obj = DataGetComplain;
						Obj.ComplainCategory = res?.data?.data;
						setTimeout(() => {
							Obj.Property = DataProperty;
						}, 500);
						setDataGetComplain(Obj);
						setDataComplainCategory(res?.data?.data);
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingComplain(false);
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();

				GetComplainCategory(id);
			}, 500);
		}
	};

	const handleSubmitComplain = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingComplain(true);
			const Obj = {
				...stateComplain,
				Property: PropertyComplainId,
				ComplainCategory: ComplainCategoryId,
				User: UserComplainId,
				id: ComplainId
			};
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${ComplainId !== "New" ? "Update-Complain" : "Request-Complain"
					}`,
					Obj,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingComplain(false);
					setStateComplain({});
					setDataGetComplain({});
					setComplainId("");
					setComplainCategoryId("");
					navigate("/Complain");
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingComplain(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitComplain();
			}, 500);
		}
	};

	const handleSubmitBill = () => {
		setLoadingComplain(true);
		const Obj = {
			id: DataGetComplain?.Bill?._id || "",
			status: "unpaid",
			Property: stateComplain?.Property,
			Complain: ComplainId,
			AdditionalCharges: AdditionalCharges,
			Field: Field,
			Discount: Discount?.Discount,
			reasonForDiscount: Discount?.reasonForDiscount,
			Remarks: Discount?.Remarks
		};
		if (Token || localStorage.getItem("token")) {
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${DataGetComplain?.Bill?._id ? "Update-Bill-Complain" : "Create-Bill-Complain"
					}`,
					Obj,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingComplain(false);
					GetComplain(ComplainId);
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingComplain(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitBill();
			}, 500);
		}
	};

	const handleSubmitPayment = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingComplain(true);
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Create-Payment-Complain`, statePayment, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingComplain(false);
					setStatePayment({
						id: "",
						Amount: 0,
						Type: "cash",
						description: "",
						Property: "",
						Complain: "",
						Bill: ""
					});
					GetComplain(ComplainId);
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoadingComplain(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingComplain(false);
			setTimeout(() => {
				CheckToken();
				handleSubmitPayment();
			}, 500);
		}
	};

	async function GetValues() {
		if (ComplainId == "New") {
			await GetProperty(PropertyComplainId);

			await GetComplainCategory(ComplainCategoryId);
		}
	}

	useEffect(() => {
		GetValues();
	}, [ComplainCategoryId, PropertyComplainId, ComplainId, stateComplain]);

	useEffect(() => {
		if (ComplainId && ComplainId != "New") {
			GetComplain(ComplainId);
		}
	}, [ComplainId]);

	useEffect(() => {
		if (ComplainId !== "New") {
			setStateComplain({
				id: ComplainId,
				ComplainCategory: DataGetComplain?.ComplainCategory?._id,
				Property: DataGetComplain?.Property?._id,
				description: DataGetComplain?.description,
				status: DataGetComplain?.status?.toLowerCase(),
				title: DataGetComplain?.title,
				VoiceNote: DataGetComplain?.VoiceNote?._id,
				User: DataGetComplain?.User?._id
			});
			setAdditionalCharges(DataGetComplain?.Bill?.AdditionalCharges || {});
			setField(DataGetComplain?.Bill?.Details || {});
			setDiscount({
				Discount: DataGetComplain?.Bill?.Discount || 0,
				reasonForDiscount: DataGetComplain?.Bill?.reasonForDiscount || ""
			});
			setStatePayment({
				id: DataGetComplain?.Payment?._id,
				Amount: DataGetComplain?.Payment?.Amount,
				Type: DataGetComplain?.Payment?.Type?.toLowerCase(),
				description: DataGetComplain?.Payment?.description,
				Property: DataGetComplain?.Property?._id,
				Complain: ComplainId,
				Bill: DataGetComplain?.Bill?._id
			});
		}
	}, [DataGetComplain]);

	return (
		<ComplainContext.Provider
			value={{
				handleChangeComplain,
				handleSubmitComplain,
				stateComplain,
				GetComplain,
				DataGetComplain,
				ComplainId,
				setComplainId,
				ComplainCategoryId,
				setComplainCategoryId,
				setStateComplain,
				handleSubmitBill,
				AdditionalCharges,
				handleAddCharges,
				Discount,
				handleDiscount,
				handleSubmitPayment,
				statePayment,
				handleChangePayment,
				PropertyComplainId,
				setPropertyComplainId,
				UserComplainId,
				setUserComplainId,
				Field,
				handleFields,
				LoadingComplain,
				setLoadingComplain
			}}
		>
			{children}
		</ComplainContext.Provider>
	);
};

export default withAuthContext(ComplainProvider);
