import React, { Fragment, useState } from "react";
import "./ChatInput.css";
import Dropzone from "react-dropzone";
import Textarea from "react-textarea-autosize";
import { Button } from "@mui/material";
import { Add, AttachFile, Mic, PlusOne, Send } from "@mui/icons-material";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import MDTypography from "components/MDTypography";
import VoiceNote from "../VoiceNote";
import ImagePicker from "components/ImagePicker";

const ChatInput = (props) => {
	const _onKeyDown = (e) => {
		if (e.keyCode === 13) {
			// ENTER
			if (e.shiftKey) {
				// allow new lines with ENTER + SHIFT
				return;
			}
			if (props.message) {
				props.onSend();
				props.onResetText();
			}
			e.preventDefault();
		}
	};
	return (
		<div
			className={`chat-input flex items-center
            ${props.state.inputHasFocus ? "chat-input-shadow" : "light-background"}`}
			style={{ overflow: "hidden" }}
		>
			{props.state?.isVoiceRecording ? (
				<VoiceNote
					handleChange={(e) => {
						setTimeout(() => {
							props.setState({ ...props.state, isVoiceRecording: false });
						}, 2000);
						props.onVoiceNote(e);
					}}
					sendRecording={() => {
						props.setState({ ...props.state, isVoiceRecording: false });
					}}
					isVoiceRecording={props.state?.isVoiceRecording}
				/>
			) : (
				<>
					<Fragment>
						<Add className="icon" />
						<EmojiEmotionsIcon icon="smile-o" className="icon" />
						<div
							className="attachment-container h100"
							onClick={() => {
								props.setState({ ...props.state, isImageUploader: true });
							}}
						>
							<AttachFile className="opacity-3 pointer icon" />
						</div>
					</Fragment>
					<Textarea
						minRows={1}
						maxRows={5}
						className={`InputField ${!props.state.inputHasFocus && "light-background"}`}
						placeholder={"Send a message..."}
						value={props.message}
						autoFocus={true}
						onChange={(e) => props.onTextInput(e.target.value)}
						onKeyDown={_onKeyDown}
						onFocus={() => {
							props.setState((prev) => ({ ...prev, inputHasFocus: true }));
						}}
						onBlur={() => {
							props.setState((prev) => ({ ...prev, inputHasFocus: false }));
						}}
					/>
					<Mic
						style={{ margin: "0px 5px" }}
						onClick={(e) => {
							props.setState({ ...props.state, isVoiceRecording: true });
						}}
					/>

					<Button
						disabled={props.LoadingChat}
						endIcon={
							<Send
								style={{
									background: "green",
									color: "#FFF",
									width: "40px",
									height: "40px",
									padding: "10px",
									borderRadius: "25%",
									margin: "0 10px"
								}}
							/>
						}
						onClick={() => {
							props.onSend();
							props.onResetText();
						}}
						style={{
							border: "2px solid green",
							padding: "5px",
							width: "200px",
							margin: "5px 10px"
						}}
					>
						<MDTypography variant="h5" style={{ paddingRight: "10px" }}>
							Send
						</MDTypography>
					</Button>
				</>
			)}
		</div>
	);
};

export default ChatInput;
