/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import UserIcon from "assets/images/bg-profile.jpeg";

import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";
import { Card } from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function Details({ Token, id, propertyId, userId, CheckToken }) {
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const [Loading, setLoading] = useState(false);

	const [state, setState] = useState({
		Number: "",
		Color: "",
		Name: "",
		Description: "",
		SlotNo: "",
		LicenseNo: "",
		Property: propertyId,
		User: userId
	});

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};

	useEffect(() => {
		if (propertyId) {
			handleChange("Property", propertyId);
		}
		if (userId) {
			handleChange("User", userId);
		}
	}, [propertyId]);

	useEffect(() => {
		if (userId) {
			handleChange("User", userId);
		}
		if (propertyId) {
			handleChange("Property", propertyId);
		}
	}, [userId]);

	useEffect(() => {
		if (propertyId) {
			handleChange("Property", propertyId);
		}
		if (userId) {
			handleChange("User", userId);
		}
	}, [propertyId, userId]);

	const handleSubmit = () => {
		if (Token) {
			setLoading(true);

			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/Create-Car`, state, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoading(false);
					navigate("/Car");
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();
				handleSubmit();
			}, 500);
		}
	};

	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				<MDButton
					variant="gradient"
					color="dark"
					onClick={() => {
						if (!Loading) {
							handleSubmit();
						} else {
							swal({
								text: "Please Let This Task Complete First",
								button: {
									text: "Ok",
									closeModal: true
								},
								icon: "warning",
								time: 3000
							});
						}
					}}
				>
					add
				</MDButton>
			</MDBox>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.SlotNo}
								label="SlotNo"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("SlotNo", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.LicenseNo}
								label="LicenseNo"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("LicenseNo", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.Number}
								label="Number"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("Number", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.Color}
								label="Color"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("Color", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.Name}
								label="Name"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("Name", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.Description}
								label="Description"
								variant="standard"
								fullWidth
								disabled={id !== "New"}
								onChange={(e) => handleChange("Description", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>{" "}
				</MDBox>
			</MDBox>
		</Card>
	);
}

export default withAuthContext(Details);
