import SignIn from "Pages/authentication/sign-in";
import Users from "Pages/User/index";
import Admins from "Pages/Admin/index";
import Service from "Pages/Service/index";
import Property from "Pages/Property/index";
import ServiceOffered from "Pages/ServiceOffered/index";
import PropertyInfo from "Pages/Property/Forms/index";
import ServiceOfferedAction from "Pages/ServiceOffered/Forms/index";
import ServiceAction from "Pages/Service/Forms/index";

// @mui icons
import Icon from "@mui/material/Icon";
import Utility from "Pages/Utility";
import UtilityInfo from "Pages/Utility/Forms";
import UserAction from "Pages/User/Forms";
import AdminAction from "Pages/Admin/Forms";
import {
	AdminPanelSettings,
	ApartmentOutlined,
	CarRental,
	ChatBubble,
	Difference,
	DisplaySettingsOutlined,
	EmojiTransportation,
	House,
	LocationCity,
	ManageAccountsOutlined,
	PictureAsPdfOutlined,
	SupervisedUserCircleOutlined
} from "@mui/icons-material";
import Visitor from "Pages/Visitor";
import VisitorAction from "Pages/Visitor/Forms/index";

import Car from "Pages/Car";
import CarAction from "Pages/Car/Forms/index";
import ComplainCategory from "Pages/ComplainCategory";
import ComplainCategoryAction from "Pages/ComplainCategory/Forms";
import Complain from "Pages/Complain";
import ComplainAction from "Pages/Complain/Forms/index";
import Chat from "Pages/Chat";
import Dashboard from "Pages/dashboard";
import { IconUser } from "@tabler/icons-react";

const routes = [
	// Dashboard
	{
		type: "collapse",
		name: "Dashboard",
		key: "Dashboard",
		private: true,
		route: "/",
		icon: <AdminPanelSettings />,
		component: <Dashboard />,
		user: true,
		manager: true,
		reception: true
	},
	// Auth
	{
		type: "auth",
		name: "Sign In",
		key: "sign-in",
		private: false,
		route: "/authentication/sign-in",
		component: <SignIn />
	},
	// Admin
	{
		type: "auth",
		private: true,
		route: "/AdminAction/:id",
		component: <AdminAction />,
		user: false,
		manager: false
	},
	{
		type: "collapse",
		name: "Admins",
		key: "Admins",
		private: true,
		route: "/Admins",
		icon: <AdminPanelSettings />,
		component: <Admins />,
		user: false,
		manager: false
	},

	// Property
	{
		type: "collapse",
		name: "Property",
		key: "Property",
		private: true,
		icon: <ApartmentOutlined />,
		route: "/Property",
		component: <Property />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/SelectProperty/:userId",
		component: <Property />,
		user: false,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/PropertyInfo/:id",
		component: <PropertyInfo />,
		user: false,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/UserProperty/:userPropertyId",
		component: <Property />,
		user: false,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/CarProperty/:carId",
		component: <Property />,
		user: false,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/ComplainPropertySelect/:ComplainCategoryId",
		component: <Property />,
		user: true,
		manager: true,
		reception: true
	},
	// Users
	{
		type: "collapse",
		name: "Unit Owners/Tenants",
		key: "AllUsers",
		private: true,
		route: "/User",
		icon: <SupervisedUserCircleOutlined />,
		component: <Users />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/UserAction/:propertyId/:id",
		component: <UserAction />,
		user: false,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/CarUser/:propertyId",
		component: <Users />,
		user: false,
		manager: true
	},

	// ServiceOffered
	{
		type: "collapse",
		name: "Services By Management",
		key: "ServiceOffered",
		private: true,
		icon: <DisplaySettingsOutlined />,
		route: "/ServiceOffered",
		component: <ServiceOffered />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/SelectServiceOffered/:serviceId",
		component: <ServiceOffered />,
		user: true,
		reception: true,
		manager: true
	},
	{
		type: "auth",
		private: true,
		route: "/ServiceOfferedAction/:id",
		component: <ServiceOfferedAction />,
		user: false,
		manager: false
	},

	//  Service
	{
		type: "collapse",
		name: "Service",
		key: "Service",
		private: true,
		icon: <ManageAccountsOutlined />,
		route: "/Service",
		component: <Service />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/ServiceAction/:id/:serviceOfferedId",
		component: <ServiceAction />,
		user: true,
		manager: true,
		reception: true
	},

	//  Car
	{
		type: "collapse",
		name: "Property Cars",
		key: "Cars",
		private: true,
		icon: <CarRental />,
		route: "/Car",
		component: <Car />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/CarAction/:propertyId/:userId/:id",
		component: <CarAction />,
		user: false,
		manager: true
	},
	//  Visitor
	{
		type: "collapse",
		name: "Visitors Report",
		key: "Visitors",
		private: true,
		icon: <IconUser />,
		route: "/Visitor",
		component: <Visitor />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/VisitorAction/:id",
		component: <VisitorAction />,
		user: false,
		manager: true,
		reception: true
	},

	//ComplainCategory
	{
		type: "collapse",
		name: "Complain By Management",
		key: "ComplainCategory",
		private: true,
		icon: <Difference />,
		route: "/ComplainCategory",
		component: <ComplainCategory />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/ComplainCategoryAction/:id",
		component: <ComplainCategoryAction />,
		user: false,
		manager: false
	},
	{
		type: "auth",
		private: true,
		route: "/ComplainCategorySelect/:ComplainId",
		component: <ComplainCategory />,
		user: true,
		manager: true,
		reception: true
	},

	// Complain
	{
		type: "collapse",
		name: "Complain",
		key: "Complain",
		private: true,
		icon: <Difference />,
		route: "/Complain",
		component: <Complain />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "auth",
		private: true,
		route: "/ComplainAction/:id/:ComplainCategoryId/:PropertyId",
		component: <ComplainAction />,
		user: true,
		manager: true,
		reception: true
	},
	{
		type: "collapse",
		name: "ComplainChat",
		key: "ComplainChat",
		private: true,
		icon: <ChatBubble />,
		route: "/ComplainChat",
		component: <Chat />,
		user: true,
		manager: true
	}

	// {
	//   type: "collapse",
	//   name: "Utility Bills",
	//   key: "Utility",
	//   private: true,
	//   route: "/Utility",
	//   component: <Utility />,
	//   icon: <PictureAsPdfOutlined />,
	// },
	// {
	//   type: "auth",
	//   private: true,
	//   route: "/UtilityAction/:propertyId/:id",
	//   component: <UtilityInfo />,
	// },
	// {
	//   type: "auth",
	//   private: true,
	//   route: "/UtilityProperty/:propertyId",
	//   component: <Property />,
	//   user: false,
	//   manager: false,
	// },
];

export default routes;
