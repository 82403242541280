// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import AdminIcon from "assets/images/bg-profile.jpeg";
import { useNavigate } from "react-router-dom";
import ImageCloud from "../../link";

function Tables({ Token, CheckToken, currAdmin, GetCurrentAdmin, AdminRole, Task, AssignTask }) {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const columns = [
		{
			headerName: "",
			field: "profilePicture",
			flex: 1,
			renderCell: (params) => (
				<img
					src={
						params?.row?.profilePicture?.filename
							? `${ImageCloud}/${params?.row?.profilePicture?.filename}`
							: AdminIcon
					}
					style={{
						width: "50px",
						height: "50px",
						borderRadius: "50px",
						border: "1px solid #000"
					}}
				/>
			)
		},
		{ headerName: "name", field: "name", flex: 1 },
		{ headerName: "email", field: "email", flex: 1 },
		{ headerName: "phone Number", field: "phoneNumber", flex: 1 },
		{ headerName: "Role", field: "Role", flex: 1 },
		{ headerName: "TowerNo", field: "TowerNo", flex: 1 },
		{ headerName: "isArchived", field: "isArchived", flex: 1 },
		{
			headerName: "Action",
			field: "action",
			flex: 1,
			renderCell: (params) => (
				<MDBox>
					<MDBadge
						badgeContent={Task ? "Assign Admin" : "Change Admin Tower"}
						color={"dark"}
						variant="gradient"
						size="sm"
						onClick={() => {
							if (Task) {
								AssignTask({ assignedTo: params?.row?._id });
							} else {
								navigate(`/AdminAction/${params?.row?._id}`);
							}
						}}
					/>
				</MDBox>
			)
		}
	];

	const GetAdmins = () => {
		if (Token || localStorage.getItem("token")) {
			axios
				.get(
					`${process.env.REACT_APP_PUBLIC_PATH}/${Task ? `GetAllAdminTask/${Task}` : "GetAllAdmins"
					}`,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then(async (res) => {
					if (res?.data?.status == 200) {
						let arr = await res?.data?.data.filter((a) => a?._id !== currAdmin);
						if (Task) {
							arr = await arr.filter((a) => a?.Role != "superadmin");
							if (AdminRole == "manger") {
								arr = await arr.filter((a) => a?.Role != "admin");
							}
						}
						await setData(await arr);
					} else {
						setError(res?.data?.message);
					}
				})
				.catch((err) => {
					setError(err?.message);
				});
		} else {
			setTimeout(() => {
				CheckToken();
				GetAdmins();
			}, 500);
		}
	};

	useEffect(() => {
		GetCurrentAdmin();
		GetAdmins();
	}, []);

	useEffect(() => {
		GetAdmins();
	}, [currAdmin, AdminRole]);

	const TableComponent = () => {
		return (
			<MDBox pt={3}>
				{data.length > 0 ? (
					<DataTable rows={data} columns={columns} />
				) : (
					<MDBox lineHeight={1} textAlign="center">
						<MDTypography
							display="block"
							variant="caption"
							color="text"
							fontWeight="medium"
							fontSize="20px"
						>
							{error ? error : " No Data Found"}
						</MDTypography>
					</MDBox>
				)}
			</MDBox>
		);
	};

	if (Task) {
		return <TableComponent />;
	} else {
		return (
			<DashboardLayout>
				<DashboardNavbar />
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									bgColor="info"
									borderRadius="lg"
									coloredShadow="info"
								>
									<Grid container style={{ width: "100%" }}>
										<Grid item xs={6}>
											<MDTypography variant="h6" color="white">
												Admin
											</MDTypography>
										</Grid>
										{AdminRole == "superadmin" && (
											<Grid
												item
												xs={6}
												style={{
													display: "flex",
													alignItems: "flex-end",
													alignContent: "flex-end",
													justifyContent: "flex-end"
												}}
											>
												<MDBadge
													badgeContent="New"
													color="dark"
													variant="gradient"
													size="sm"
													onClick={() => navigate(`/AdminAction/New`)}
												/>
											</Grid>
										)}
									</Grid>
								</MDBox>

								<TableComponent />
							</Card>
						</Grid>
					</Grid>
				</MDBox>
			</DashboardLayout>
		);
	}
}

export default withAuthContext(Tables);
