/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// ServiceOfferedAction page components
import DetailsForm from "Pages/ServiceOffered/Forms/components/Details";
import Fields from "Pages/ServiceOffered/Forms/components/Fields";
import ServiceOfferedProvider from "context/ServiceOffered";

function ServiceOfferedAction() {
	return (
		<ServiceOfferedProvider>
			<DashboardLayout>
				<>
					<DashboardNavbar absolute isMini />
					<MDBox mt={8}>
						<MDBox mb={3}>
							<Grid container spacing={3}>
								<Grid item xs={12} lg={8}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<DetailsForm />
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12} lg={4}>
									<Fields />
								</Grid>
							</Grid>
						</MDBox>
					</MDBox>
				</>
			</DashboardLayout>
		</ServiceOfferedProvider>
	);
}

export default ServiceOfferedAction;
