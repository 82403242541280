import { useEffect, useRef } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const VoiceNote = ({ handleChange, File }) => {
  const Ref = useRef(null);
  function generateRandomFileName(length) {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }

    return result;
  }

  const recorderControls = useAudioRecorder();
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64data = event.target.result;
      handleChange({
        data: base64data,
        name: generateRandomFileName(20) + ".webm",
        type: "audio/webm",
      });
    };
    reader.readAsDataURL(blob);
    if (Ref.current) {
      Ref.current.src = url;
      Ref.current.controls = true;
    }
  };

  useEffect(() => {
    if (Ref.current) {
      Ref.current.src = File;
      Ref.current.controls = true;
    }
  }, [File]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        gap: 10,
        margin: "20px 0px",
      }}
    >
      <audio ref={Ref} />
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
      />
    </div>
  );
};

export default VoiceNote;
