import React, { createContext, useEffect, useState } from "react";
import { withAuthContext } from "./Auth";
import axios from "axios";
import swal from "sweetalert";

export const PropertyContext = createContext();

export const withPropertyContext = (Component) => (props) =>
	(
		<PropertyContext.Consumer>
			{(value) => <Component {...value} {...props} />}
		</PropertyContext.Consumer>
	);

const PropertyProvider = ({ children, Token, CheckToken }) => {
	const [AllProperty, setAllProperty] = useState([]);
	const [errorAllProperty, setErrorAllProperty] = useState("");

	const [LoadingProperty, setLoadingProperty] = useState(false);

	const GetProperties = () => {
		if (Token || localStorage.getItem("token")) {
			setLoadingProperty(true);
			axios
				.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllProperty`, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					setLoadingProperty(false);
					if (res?.data?.status == 200) {
						setAllProperty(res?.data?.data);
					} else {
						setErrorAllProperty(res?.data?.message);
					}
				})
				.catch((err) => {
					setLoadingProperty(false);
					setErrorAllProperty(err?.message);
				});
		} else {
			setLoadingProperty(false);
			setTimeout(() => {
				CheckToken();
				GetProperties();
			}, 500);
		}
	};

	const AddPropertyToUser = ({ userId, propertyId, Type, navigate }) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingProperty(true);
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/Property-User`,
					{
						property: propertyId,
						user: userId,
						Type: Type ? Type : "owner"
					},
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingProperty(false);
					if (res?.data?.status == 200) {
						swal({
							text: "Property was added to user",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: res?.data?.status == 200 ? "success" : "error",
							time: 3000
						});
						GetProperties();
						navigate("/User");
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingProperty(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingProperty(false);
			setTimeout(() => {
				CheckToken();
				AddPropertyToUser({ userId, propertyId, navigate });
				GetProperties();
			}, 500);
		}
	};

	const RemovePropertyToUser = ({ userId, propertyId, GetUsers }) => {
		if (Token || localStorage.getItem("token")) {
			setLoadingProperty(true);
			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/Remove-Property-User`,
					{
						property: propertyId,
						user: userId
					},
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoadingProperty(false);
					if (res?.data?.status == 200) {
						swal({
							text: "Property was removed to user",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: res?.data?.status == 200 ? "success" : "error",
							time: 3000
						});
						GetUsers();
					} else {
						swal({
							text: "There was some Error",
							button: {
								text: "Ok",
								closeModal: true
							},
							icon: "error",
							time: 3000
						});
					}
				})
				.catch((err) => {
					setLoadingProperty(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoadingProperty(false);
			setTimeout(() => {
				CheckToken();
				RemovePropertyToUser({ userId, propertyId, GetUsers });
				GetProperties();
			}, 500);
		}
	};

	useEffect(() => {
		GetProperties();
	}, []);
	return (
		<PropertyContext.Provider
			value={{
				AllProperty,
				errorAllProperty,
				GetProperties,
				AddPropertyToUser,
				RemovePropertyToUser,
				LoadingProperty,
				setLoadingProperty
			}}
		>
			{children}
		</PropertyContext.Provider>
	);
};

export default withAuthContext(PropertyProvider);
