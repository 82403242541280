// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/MUI";

// Data
import axios from "axios";
import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import MDBadge from "components/MDBadge";
import ServiceOfferedIcon from "assets/images/bg-profile.jpeg";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Checkbox } from "@mui/material";
import ImageCloud from "../../link";

function Tables({ AdminRole }) {
	const { serviceId } = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [error, setError] = useState("");

	const columns = [
		{
			headerName: "",
			field: "Icon",
			flex: 1,
			renderCell: ({ row: a }) => (
				<img
					src={
						a?.Icon?.filename && `${ImageCloud}/${a?.Icon?.filename}`
							? `${ImageCloud}/${a?.Icon?.filename}`
							: ServiceOfferedIcon
					}
					style={{
						width: "50px",
						height: "50px",
						borderRadius: "50px",
						border: "1px solid #000"
					}}
				/>
			)
		},
		{ headerName: "title", field: "title", flex: 1 },
		{
			headerName: "Fields",
			field: "Fields",
			flex: 1,
			valueGetter: ({ row: a }) => `${a?.Fields?.length || 0}`
		},
		{
			headerName: "Service",
			field: "Service",
			flex: 1,
			valueGetter: ({ row: a }) => `${a?.Service?.length || 0}`
		},
		{
			headerName: "Action",
			field: "action",
			flex: 1,
			renderCell: ({ row: a }) => (
				<MDBox>
					<MDBadge
						badgeContent={serviceId ? "Select Service" : "Edit"}
						color={"warning"}
						variant="gradient"
						size="sm"
						onClick={() => {
							if (serviceId) {
								navigate(`/ServiceAction/New/${a?._id}`);
							} else if (AdminRole == "superadmin") {
								navigate(`/ServiceOfferedAction/${a?._id}`);
							} else {
								swal({
									text: "There was some Error or You are not allowed for the action",
									button: {
										text: "Ok",
										closeModal: true
									},
									icon: "error",
									time: 3000
								});
							}
						}}
					/>
				</MDBox>
			)
		}
	];
	const GetServiceOffereds = () => {
		axios
			.get(`${process.env.REACT_APP_PUBLIC_PATH}/GetAllServiceOffered`)
			.then((res) => {
				if (res?.data?.status == 200) {
					setData(res?.data?.data.reverse());
				} else {
					setError(res?.data?.message);
				}
			})
			.catch((err) => {
				setError(err?.message);
			});
	};

	useEffect(() => {
		GetServiceOffereds();
	}, []);

	const ServiceOfferedTable = () => {
		return (
			<Card>
				<MDBox
					mx={2}
					mt={-3}
					py={3}
					px={2}
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
				>
					<Grid container style={{ width: "100%" }}>
						<Grid item xs={6}>
							<MDTypography variant="h6" color="white">
								Service Offered
							</MDTypography>
						</Grid>
						{AdminRole == "superadmin" && (
							<Grid
								item
								xs={6}
								style={{
									display: "flex",
									alignItems: "flex-end",
									alignContent: "flex-end",
									justifyContent: "flex-end"
								}}
							>
								<MDBadge
									badgeContent="New"
									color="dark"
									variant="gradient"
									size="sm"
									onClick={() => navigate(`/ServiceOfferedAction/New`)}
								/>
							</Grid>
						)}
					</Grid>
				</MDBox>

				<MDBox pt={3}>
					{data.length > 0 ? (
						<DataTable rows={data} columns={columns} />
					) : (
						<MDBox lineHeight={1} textAlign="center">
							<MDTypography
								display="block"
								variant="caption"
								color="text"
								fontWeight="medium"
								fontSize="20px"
							>
								{error ? error : " No Data Found"}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</Card>
		);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<ServiceOfferedTable />
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default withAuthContext(Tables);
