import React from "react";
import "./ChatMessage.css";
import { Emoji } from "emoji-mart";
import moment from "moment";
import Icon from "@mui/material/Icon";

const ChatMessage = (props) => {
  const {
    time,
    ago,
    textWithLineBreaks,
    showBackground,
    showPadding,
    userProfileImageUrl,
    profileImageUrl,
    opponent,
  } = generateChatMessageInfo(props);

  const renderOwnMessage = () => (
    <div className="message-padding">
      <div className="flex flex-bottom">
        <div className="message-container message-container-padding-right flex-right">
          <div
            style={{
              background: showBackground
                ? `${props.userSpeechBubbleColor}`
                : null,
              cursor: "pointer",
            }}
            className={`white-1${showPadding ? " padding-all" : ""
              } radius time-trigger`}
          >
            <p className="margin-0">{textWithLineBreaks}</p>
          </div>

          <div
            className="right"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Icon icon="check" />
            <p className="opacity-4 padding-top-2 chat-mine-margin">{time}</p>
            <img src={userProfileImageUrl} alt="" className="avatarme" />
          </div>
        </div>
      </div>
    </div>
  );

  const renderAgentMessage = () => (
    <div className="message-padding">
      <div className="flex flex-bottom">
        <img src={profileImageUrl} alt="" className="avatar message-avatar" />
        <div
          className="message-container message-container-padding-left"
          style={{ alignItems: "flex-start" }}
        >
          <div
            className="opaque padding-20 radius time-trigger"
            style={{ background: "#EAECF2" }}
          >
            <p className="margin-0">{textWithLineBreaks}</p>
          </div>
          {!props.shouldRenderTimestamp && (
            <p className="right opacity-4 padding-top-2 time-on-hover">
              {time}
            </p>
          )}
          {props.shouldRenderTimestamp && (
            <p className="right opacity-4 padding-top-2">{ago}</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="fadeInLeft">
      {(window.__VUE_DASHBOARD || opponent) &&
        !(window.__VUE_DASHBOARD && opponent)
        ? renderAgentMessage()
        : renderOwnMessage()}
    </div>
  );
};

const generateChatMessageInfo = (props) => {
  const ago = props.message.loading
    ? (props.message.image
      ? props.getLanguageString("uploading")
      : props.getLanguageString("sending")) + "..."
    : moment(props.message.createdAt).fromNow();
  const time = new Date(props.message.createdAt).toLocaleTimeString();
  const opponent = props.message.opponent;
  const profileImageUrl =
    (opponent?.imageUrl ? opponent?.imageUrl : opponent?.profileImageURL) || props?.profileImageURL;
  const userProfileImageUrl = props?.userProfileImageUrl;
  const textWithLineBreaks = props.message?.VoiceNote ? (
    <audio src={props.message?.VoiceNote} controls={true} />
  ) : props.message?.gif || props.message?.image ? (
    <img
      style={{ width: 128 }}
      src={props.message?.gif || props.message?.image}
    />
  ) : props.message.emoji ? (
    <Emoji emoji={props.message?.emoji} size={64} />
  ) : (
    props.message?.text?.split("\n").map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ))
  );

  return {
    time,
    ago,
    opponent,
    profileImageUrl,
    userProfileImageUrl,
    textWithLineBreaks,
    showBackground: !(
      props.message.image ||
      props.message.emoji ||
      props.message.gif
    ),
    showPadding: !(props.message.image || props.message.gif),
  };
};

export default ChatMessage;
