/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import UserIcon from "assets/images/bg-profile.jpeg";

import { useEffect, useState } from "react";
import { withAuthContext } from "context/Auth";
import axios from "axios";
import {
	Card,
	Checkbox,
	FormControlLabel,
	Icon,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import MDInput from "components/MDInput";
import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import ImagePicker from "components/ImagePicker";
import { withPropertyContext } from "context/Property";
import { RemoveRedEyeOutlined, VisibilityOff } from "@mui/icons-material";
import AdditionalDetails from "./AdditionalInformation"
import ImageCloud from "../../../../../link";

function Details({ Token, id, data, GetUser, propertyId, CheckToken, AddPropertyToUser }) {
	const navigate = useNavigate();
	const [error, setError] = useState("");
	const [Loading, setLoading] = useState(false);

	const [state, setState] = useState({
		id: id,
		name: "",
		email: "",
		Type: "",
		phoneNumber: "",
		password: "",
		flatNo: "",
		notifications: false,
		TermsAndConditions: false,
		isVerified: false,
		profilePicture: {},
		showPassword: false,
		Qualification: "",
		Designation: "",
		Proffesion: "",
		NameOfOrganization: "",
		AddressOfOrganization: "",
		PresentAddress: "",
		PermanentAddress: "",
		TelePhoneOffice: "",
		Residence: "",
		Fax: "",
		Other: "",
		NoOfFamilyMembers: "",
		Adults: "",
		Children: "",
		CNIC: [],
	});

	const handleChange = (name, value) => {
		setState({ ...state, [name]: value });
	};

	useEffect(() => {
		if (id !== "New" && data?._id) {
			setState({
				id: id,
				name: data?.name,
				email: data?.email,
				phoneNumber: data?.phoneNumber,
				password: data?.password,
				flatNo: data?.flatNo,
				notifications: data?.notifications,
				TermsAndConditions: data?.TermsAndConditions,
				isVerified: data?.isVerified,
				preview: `${ImageCloud}/${data?.profilePicture?.filename}`,
				Qualification: data?.Qualification,
				Designation: data?.Designation,
				Proffesion: data?.Proffesion,
				NameOfOrganization: data?.NameOfOrganization,
				AddressOfOrganization: data?.AddressOfOrganization,
				PresentAddress: data?.PresentAddress,
				PermanentAddress: data?.PermanentAddress,
				TelePhoneOffice: data?.TelePhoneOffice,
				Residence: data?.Residence,
				Fax: data?.Fax,
				Other: data?.Other,
				NoOfFamilyMembers: data?.NoOfFamilyMembers,
				Adults: data?.Adults,
				Children: data?.Children,
				CNIC: data?.CNIC,
			});
		}
	}, [data]);

	useEffect(() => {
		if (propertyId) {
			handleChange("Property", propertyId);
		}
	}, [propertyId]);

	const handleSubmit = () => {
		if (state?.id && Token) {
			setLoading(true);
			swal({
				text: "This may take a bit time as sending email to users",
				button: {
					text: "Ok",
					closeModal: true
				},
				icon: "warning"
			});
			axios
				.post(`${process.env.REACT_APP_PUBLIC_PATH}/SignUp`, state, {
					headers: {
						Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
					}
				})
				.then((res) => {
					if (id == "New") {
						setLoading(false);
						AddPropertyToUser({
							userId: res?.data?.id,
							propertyId: propertyId,
							Type: state?.Type,
							navigate
						});
					} else {
						GetUser();
					}
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setTimeout(() => {
				CheckToken();
				handleSubmit();
			}, 500);
		}
	};

	const handleImageSelected = (imageData) => {
		handleChange("profilePicture", imageData);
	};

	const generatePassword = (length = 10) => {
		const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
		let result = "";
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		handleChange("password", result.toString());
	};
	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				{id == "New" && (
					<MDButton
						variant="gradient"
						color="dark"
						disabled={Loading}
						onClick={() => {
							if (!Loading) {
								handleSubmit();
							}
						}}
					>
						{Loading ? "Creating User ..." : "add"}
					</MDButton>
				)}
			</MDBox>
			<ImagePicker
				onImageSelected={handleImageSelected}
				preview={state?.preview}
				disabled={id !== "New" || Loading}
			/>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.name}
								label="name"
								variant="standard"
								fullWidth
								disabled={id !== "New" || Loading}
								onChange={(e) => handleChange("name", e.target.value)}
							/>
						</MDBox>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.email}
								label="email"
								variant="standard"
								fullWidth
								disabled={id !== "New" || Loading}
								onChange={(e) => handleChange("email", e.target.value)}
							/>
						</MDBox>{" "}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.phoneNumber}
								label="phoneNumber"
								variant="standard"
								fullWidth
								type="number"
								disabled={id !== "New" || Loading}
								onChange={(e) => handleChange("phoneNumber", e.target.value)}
							/>
						</MDBox>{" "}
						{id == "New" && (
							<MDBox pl={2} width="100%">
								<MDInput
									value={state?.password}
									label="password"
									variant="standard"
									fullWidth
									type={state?.showPassword ? "text" : "password"}
									disabled={id !== "New" || Loading}
									onChange={(e) => handleChange("password", e.target.value)}
								/>
								{state?.showPassword ? (
									<RemoveRedEyeOutlined
										onClick={() => handleChange("showPassword", !state?.showPassword)}
									/>
								) : (
									<VisibilityOff
										onClick={() => handleChange("showPassword", !state?.showPassword)}
									/>
								)}
								<MDButton
									variant="gradient"
									color="dark"
									disabled={Loading}
									onClick={() => {
										generatePassword();
									}}
								>
									{Loading ? "Loading ..." : "Auto Password"}
								</MDButton>
							</MDBox>
						)}
					</MDBox>
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						<MDBox pl={2} width="100%">
							<MDInput
								value={state?.flatNo}
								label="flatNo"
								variant="standard"
								fullWidth
								disabled={id !== "New" || Loading}
								onChange={(e) => handleChange("flatNo", e.target.value)}
							/>
						</MDBox>{" "}
						{id != "New" && (
							<MDBox pl={2} width="100%">
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) => handleChange("notifications", !state?.notifications)}
											checked={state?.notifications}
										/>
									}
									label="notifications"
									disabled={id !== "New" || Loading}
								/>
							</MDBox>
						)}
					</MDBox>{" "}
					{id != "New" && (
						<MDBox
							mb={2}
							pt={2}
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="100%"
						>
							<MDBox pl={2} width="100%">
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) =>
												handleChange("TermsAndConditions", !state?.TermsAndConditions)
											}
											checked={state?.TermsAndConditions}
										/>
									}
									label="TermsAndConditions"
									disabled={id !== "New" || Loading}
								/>
							</MDBox>{" "}
							<MDBox pl={2} width="100%">
								<FormControlLabel
									control={
										<Checkbox
											onChange={(e) => handleChange("isVerified", !state?.isVerified)}
											checked={state?.isVerified}
										/>
									}
									label="isVerified"
									disabled={id !== "New" || Loading}
								/>
							</MDBox>
						</MDBox>
					)}
					<MDBox
						mb={2}
						pt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						width="100%"
					>
						{id == "New" && (
							<MDBox pl={2} width="100%">
								<InputLabel variant="standard" htmlFor="uncontrolled-native">
									Type
								</InputLabel>
								<Select
									value={state?.Type}
									label={"Type"}
									variant="standard"
									fullWidth
									onChange={(e) => handleChange("Type", e.target.value)}
									style={{ textTransform: "uppercase" }}
								>
									<MenuItem value={"owner"}>owner</MenuItem>
									<MenuItem value={"rentant"}>rentant</MenuItem>
								</Select>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
			</MDBox>

			{id != "New" && (
				<AdditionalDetails state={state} />
			)}
		</Card>
	);
}

export default withAuthContext(withPropertyContext(Details));
