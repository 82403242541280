import React, { useEffect, useState } from "react";
import Chatmessages from "./ChatAppComponents/ChatMessages";
import ChatInput from "./ChatAppComponents/ChatInput";
import Header from "./ChatAppHeader";
import UserImg from "../../../../../assets/images/Bg.jpeg";
import MeImg from "../../../../../assets/images/Logo.png";
import { withChatContext } from "context/Chat";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import ImagePicker from "components/ImagePicker";
import MDTypography from "components/MDTypography";
import ImageCloud from "../../../../../link";

const initialState = {
  inputHasFocus: true,
  isEmojiPopoverOpen: false,
  isGifPopoverOpen: false,
  isVoiceRecording: false,
  isImageUploader: false,
};

function ChatComponent({ socket, handleMessageSocket, SendMessage, Chat, LoadingChat }) {

  useEffect(() => {
    socket.on('newmessage', (data) => {
      handleMessageSocket(data)
    })
    return () => {
      socket.off('newmessage', (data) => handleMessageSocket(data));
    };
  }, [])

  const [state, setState] = useState({ ...initialState });
  const [image, setImage] = useState({});

  const [input, setInput] = useState("");
  return (
    <div>
      <Header />
      <div
        className="chat-app"
        style={{ position: "relative", background: "#FFF" }}
      >
        {state?.isImageUploader ? (
          <div
            style={{
              height: "calc(100vh - 200px)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <ImagePicker
              onImageSelected={(e) => {
                setImage(e);
              }}
            />
            <Button
              disabled={LoadingChat}
              endIcon={
                <Send
                  style={{
                    background: "green",
                    color: "#FFF",
                    width: "40px",
                    height: "40px",
                    padding: "10px",
                    borderRadius: "25%",
                    margin: "0 10px",
                  }}
                />
              }
              onClick={async () => {
                if (image?.name) {
                  await SendMessage({ Media: image });
                }
                setState(initialState);
                setInput("");
              }}
              style={{
                border: "2px solid green",
                padding: "5px",
                width: "200px",
                margin: "5px 10px",
              }}
            >
              <MDTypography variant="h5" style={{ paddingRight: "10px" }}>
                Send
              </MDTypography>
            </Button>
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 200px)", overflow: "hidden" }}>
            <Chatmessages
              style={{ height: "calc(100vh - 280px)" }}
              opponentImageURL={
                Chat?.User?.profilePicture?.filename
                  ? `${ImageCloud}/${Chat?.User?.profilePicture?.filename}`
                  : UserImg
              }
              userSpeechBubbleColor="linear-gradient(0deg, rgba(148,188,88,1) 0%, rgba(131,175,87,1) 38%, rgba(86,140,85,1) 76%, rgba(62,121,84,1) 99%)"
              translate={(k) => k}
              userProfileImageUrl={MeImg}
              LoadingChat={LoadingChat}
            />
            <ChatInput
              onEmoji={() => { }}
              onGif={() => { }}
              onTextInput={setInput}
              onDrop={() => { }}
              LoadingChat={LoadingChat}
              onVoiceNote={async (e) => {
                if (e?.name) {
                  await SendMessage({ VoiceNote: e });
                  setInput("");
                }
              }}
              message={input}
              onSend={async () => {
                if (input.trim() != "" && input.trim() != "\n") {
                  await SendMessage({ text: input.trim() });
                  setInput("");
                }
              }}
              onResetText={() => setInput("")}
              state={state}
              setState={setState}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default withChatContext(ChatComponent);
