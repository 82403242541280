/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import { withAuthContext } from "context/Auth";
import { Card, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import ImagePicker from "components/ImagePicker";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ImageCloud from "../../../../../link";

function Details({ CheckToken, Token }) {
	const handleImageSelected = (imageData) => {
		handleChange("Icon", imageData);
	};
	let { id } = useParams();
	const [DataGetComplain, setDataGetComplain] = useState();
	const [Loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const GetComplainCategory = () => {
		setLoading(true);

		axios
			.get(`${process.env.REACT_APP_PUBLIC_PATH}/ComplainCategoryInfo/${id}`)
			.then((res) => {
				setLoading(false);
				if (res?.data?.status == 200) {
					setDataGetComplain(res?.data?.data);
				} else {
					swal({
						text: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
					// navigate("/ComplainCategory");
				}
			})
			.catch((err) => {
				setLoading(false);
				swal({
					text: err?.message ? err?.message : "There was some Error",
					button: {
						text: "Ok",
						closeModal: true
					},
					icon: "error",
					time: 3000
				});
				// navigate("/ComplainCategory");
			});
	};

	useEffect(() => {
		if (id !== "New") GetComplainCategory();
	}, [id]);

	const [stateComplainCategory, setStateComplainCategory] = useState({
		id: "",
		title: "",
		Icon: {},
		preview: ""
	});

	useEffect(() => {
		if (id !== "New") {
			setStateComplainCategory({
				id: id,
				title: DataGetComplain?.title,
				preview: DataGetComplain?.Icon?.filename
					? `${ImageCloud}/${DataGetComplain?.Icon?.filename}`
					: ""
			});
		}
	}, [DataGetComplain]);

	const handleChange = (name, value) => {
		setStateComplainCategory({ ...stateComplainCategory, [name]: value });
	};

	const handleSubmit = () => {
		if (id !== "New") {
			handleChange("id", id);
		}
		if (Token || localStorage.getItem("token")) {
			setLoading(true);

			axios
				.post(
					`${process.env.REACT_APP_PUBLIC_PATH}/${
						id !== "New" ? "Update-ComplainCategory" : "Create-ComplainCategory"
					}`,
					stateComplainCategory,
					{
						headers: {
							Authorization: Token ? `${Token}` : `${localStorage.getItem("token")}`
						}
					}
				)
				.then((res) => {
					setLoading(false);
					if (id == "New") {
						navigate(
							res?.data?.id ? `/ComplainCategoryAction/${res?.data?.id}` : `/ComplainCategory`
						);
						window.location.reload(false);
					}
					setStateComplainCategory({
						id: id,
						title: "",
						Fields: [],
						Icon: {},
						preview: ""
					});
					GetComplainCategory();
					swal({
						text: res?.data?.message,
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: res?.data?.status == 200 ? "success" : "error",
						time: 3000
					});
				})
				.catch((err) => {
					setLoading(false);
					swal({
						text: err?.response?.data?.message
							? err?.response?.data?.message
							: "There was some Error",
						button: {
							text: "Ok",
							closeModal: true
						},
						icon: "error",
						time: 3000
					});
				});
		} else {
			setLoading(false);
			setTimeout(() => {
				CheckToken();

				handleSubmit();
			}, 500);
		}
	};
	return (
		<Card id="delete-account">
			<MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
				<MDTypography variant="h6" fontWeight="medium">
					Basic Details
				</MDTypography>
				<MDButton
					variant="gradient"
					color="dark"
					onClick={() => {
						if (!Loading) {
							handleSubmit();
						} else {
							swal({
								text: "Please Let This Task Complete First",
								button: {
									text: "Ok",
									closeModal: true
								},
								icon: "warning",
								time: 3000
							});
						}
					}}
				>
					{id !== "New" ? "update" : "Add"}
				</MDButton>
			</MDBox>

			<ImagePicker onImageSelected={handleImageSelected} preview={stateComplainCategory?.preview} />
			{/* {selectedImage && (
        <div>
          <h2>Selected Image Preview</h2>
          <img src={selectedImage} alt="Selected" className="selected-image" />
        </div>
      )} */}

			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox mb={2}>
						<MDInput
							value={stateComplainCategory?.title}
							label="title"
							variant="standard"
							fullWidth
							onChange={(e) => handleChange("title", e.target.value)}
						/>
					</MDBox>
				</MDBox>
			</MDBox>
		</Card>
	);
}

export default withAuthContext(Details);
