/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and permission this notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import { withAuthContext } from "context/Auth";
import { useEffect, useState } from "react";
import Services from "Pages/Service";
import Complains from "Pages/Complain";
import generateFrontendData from "./data/reportsLineChartData";

function Dashboard({ DashboardData, GetDashboard, AdminRole }) {
	useEffect(() => {
		GetDashboard();
	}, []);
	const [data, setData] = useState({ Bills: {}, Payment: {}, time: new Date().toDateString() });
	useEffect(async () => {
		const { Bills, Payment } = await generateFrontendData(DashboardData);
		console.log(Bills, Payment, generateFrontendData(DashboardData));
		await setData({ Bills: await Bills, Payment: await Payment, time: new Date().toDateString() });
	}, [DashboardData]);
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox py={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox mb={1.5}>
							<ComplexStatisticsCard
								color="dark"
								icon="weekend"
								title="Forwarded"
								count={DashboardData?.Forwarded?.length}
								percentage={{
									color: "success",
									amount:
										`${Math.round(
											(DashboardData?.Forwarded?.length / DashboardData?.Tasks?.length) * 100
										)}%` || "0%",
									label: " Tasks were Forwarded this month"
								}}
							/>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox mb={1.5}>
							<ComplexStatisticsCard
								icon="leaderboard"
								title="Pending"
								count={DashboardData?.Pending?.length}
								percentage={{
									color: "success",
									amount:
										`${Math.round(
											(DashboardData?.Pending?.length / DashboardData?.Tasks?.length) * 100
										)}%` || "0%",
									label: " Tasks were Pending this month"
								}}
							/>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox mb={1.5}>
							<ComplexStatisticsCard
								color="success"
								icon="store"
								title="Completed"
								count={DashboardData?.Completed?.length}
								percentage={{
									color: "success",
									amount:
										`${Math.round(
											(DashboardData?.Completed?.length / DashboardData?.Tasks?.length) * 100
										)}%` || "0%",
									label: " Tasks were Completed this month"
								}}
							/>
						</MDBox>
					</Grid>
					<Grid item xs={12} md={6} lg={3}>
						<MDBox mb={1.5}>
							<ComplexStatisticsCard
								color="primary"
								icon="person_add"
								title="InProgress"
								count={DashboardData?.InProgress?.length}
								percentage={{
									color: "success",
									amount:
										`${Math.round(
											(DashboardData?.InProgress?.length / DashboardData?.Tasks?.length) * 100
										)}%` || "0%",
									label: " Tasks were InProgress this month"
								}}
							/>
						</MDBox>
					</Grid>
				</Grid>
				{AdminRole == "admin" ||
					(AdminRole == "superadmin" && (
						<MDBox mt={4.5}>
							<Grid container spacing={3}>
								{data?.Bills?.datasets && (
									<Grid item xs={12} md={6} lg={4}>
										<MDBox mb={3}>
											<ReportsLineChart
												color="success"
												title="Bills"
												description={<>This Year Bills</>}
												date={data?.time}
												chart={data?.Bills}
											/>
										</MDBox>
									</Grid>
								)}
								{data?.Payment?.datasets && (
									<Grid item xs={12} md={6} lg={4}>
										<MDBox mb={3}>
											<ReportsLineChart
												color="dark"
												title="Payments"
												description={<>This Year Payments</>}
												date={data?.time}
												chart={data?.Payment}
											/>
										</MDBox>
									</Grid>
								)}
							</Grid>
						</MDBox>
					))}

				<MDBox py={10}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={6}>
							<Services TableOnly={true} />
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<Complains TableOnly={true} />
						</Grid>
					</Grid>
				</MDBox>
			</MDBox>
		</DashboardLayout>
	);
}

export default withAuthContext(Dashboard);
